import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../PageTitle/PageTitle';

const styles = {
    feedbackContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
};

class ProfilePage extends Component {
    state = {
        password: '',
        newPassword: '',
        retypeNewPassword: '',
        errorText: '',
        statusText: '',
    };

    componentDidMount() {
        const {
            dispatch,
        } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    changePassword = (event) => {
        event.preventDefault();
        const { dispatch } = this.props;
        const {
            password,
            newPassword,
            retypeNewPassword,
        } = this.state;
        if (retypeNewPassword !== newPassword) {
            this.setState({
                errorText: 'Passwords do not match.',
            });
        } else if (newPassword && newPassword.length < 8) {
            this.setState({
                errorText: 'Password must be at least 8 characters.',
            });
        } else if (newPassword && newPassword.length > 7) {
            dispatch({ type: 'CHANGE_PASSWORD', payload: { newPassword, oldPassword: password } });
            this.setState({
                password: '',
                newPassword: '',
                retypeNewPassword: '',
                errorText: '',
                statusText: '',
            });
        } else {
            this.setState({
                errorText: 'Please enter a password.',
            });
        }
    } // end login

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
            [propertyName]: event.target.value,
            errorText: '',
        });
    }

    render() {
        const {
            password,
            errorText,
            statusText,
            newPassword,
            retypeNewPassword,
        } = this.state;
        const { classes } = this.props;
        return (
            <div className="container">
                <PageTitle title="Profile" />
                <Paper>
                    <Typography>CHANGE PASSWORD</Typography>
                    <form onSubmit={this.changePassword}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    id="current-password"
                                    label="Current Password"
                                    type="password"
                                    className={classes.textField}
                                    value={password}
                                    onChange={this.handleInputChangeFor('password')}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    id="new-password"
                                    label="New Password"
                                    type="password"
                                    className={classes.textField}
                                    value={newPassword}
                                    onChange={this.handleInputChangeFor('newPassword')}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    id="retype-new-password"
                                    label="Retype New Password"
                                    type="password"
                                    className={classes.textField}
                                    value={retypeNewPassword}
                                    onChange={this.handleInputChangeFor('retypeNewPassword')}
                                    margin="dense"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                justify="flex-end"
                                container
                            >
                                <Button variant="contained" type="submit">Change Password</Button>
                            </Grid>
                        </Grid>

                        <br />
                        {
                            errorText && errorText !== '' && (
                                <div style={{ color: 'red', width: '100%' }}>{errorText}</div>
                            )
                        }
                        {
                            statusText && statusText !== '' && (
                                <div style={{ width: '100%' }}>{statusText}</div>
                            )
                        }
                    </form>
                </Paper>
            </div>
        );
    }
}

ProfilePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default connect()(withStyles(styles)(ProfilePage));

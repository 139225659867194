import React from 'react';
import {
    TableRow,
    TableCell,
    Hidden,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { shortStatusText } from '../../../helpers/CourseWorkTextHelper';
import { shortDateTime } from '../../../helpers/DateFormatHelper';
import FeedbackTooltip from '../../General/FeedbackTooltip';

const styles = {
    assignmentTitleText: {
        width: '100%',
        padding: '0px 15px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

const getGradeForCourseWork = (courseWork) => {
    let result = '';
    if (courseWork.workflow_state === 'graded') {
        result = courseWork.grade;
    }
    return result;
};

const getDefaultStatus = (assignment) => {
    let defaultStatus = <span>Not assigned</span>;
    if (assignment.workflow_state === 'assigned') {
        // Assigned but nothing has been turned in
        defaultStatus = <span className="status-text status-missing">Missing</span>;
    }
    return defaultStatus;
};

const getEmployeeFullname = (employee) => {
    return employee.first_name + ' ' + employee.last_name;
}

const CohortAssignmentRow = ({
    student,
    viewCourseWork,
    assignment,
    classes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={viewCourseWork(student)}
    >
        <TableCell>{`${student.first_name} ${student.last_name}`}</TableCell>
        {
            assignment.assignment_type === 'graded'
            && (
                <>
                <TableCell>
                    {
                        student.course_works.length > 0 ? (
                            getGradeForCourseWork(
                                student.course_works[0],
                            )
                        ) : (
                            <span />
                        )
                    }
                </TableCell>
                <TableCell>
                    {
                        student.course_works.length > 0 && student.course_works[0].employee ? (
                            <span>{getEmployeeFullname(student.course_works[0].employee)}</span>
                        ) : (
                            <span />
                        )
                    }
                </TableCell>
                </>
            )
        }
        <TableCell>
            {
                student.course_works.length > 0
                && student.course_works[0].instructor_comment
                && (
                    // Not sure if it makes sense to have a feedback bubble here
                    <FeedbackTooltip
                        title="Instructor Comments"
                        tooltip={
                            `${student.course_works[0].instructor_comment.substring(0, 120)}... (click to view more)`
                        }
                    />
                )
            }
        </TableCell>
        <Hidden smDown>
            <TableCell>
                {
                    student.course_works.length > 0
                    && student.course_works[0].completed_at
                    && (
                        shortDateTime(
                            student.course_works[0].completed_at,
                        )
                    )
                }
            </TableCell>
        </Hidden>
        <TableCell align="right">
            {
                student.course_works.length > 0 ? (
                    shortStatusText(
                        student.course_works[0],
                        assignment,
                    )
                ) : (
                    getDefaultStatus(assignment)
                )
            }
        </TableCell>
    </TableRow>
);

CohortAssignmentRow.propTypes = {
    student: PropTypes.instanceOf(Object).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    viewCourseWork: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortAssignmentRow);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Divider,
} from '@material-ui/core';
import StudentProfile from './StudentProfile/StudentProfile';
import StudentFeedbackGraph from './StudentFeedbackGraph/StudentFeedbackGraph';
import StudentCourseWork from './StudentCourseWork/StudentCourseWork';
import StudentPlacementTable from './StudentPlacementTable/StudentPlacementTable';
import PageTitle from '../../PageTitle/PageTitle';
import CourseWorkModal from '../../Modal/CourseWorkModal';

class StudentDetailPage extends Component {
    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'UNSET_STUDENT' });
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
    }

    viewCourseWork = (courseWork, assignment) => () => {
        const { dispatch, student } = this.props;
        dispatch({ type: 'SET_ASSIGNMENT_STUDENT', payload: student });
        dispatch({ type: 'SET_ASSIGNMENT_SELECTED', payload: assignment });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: courseWork });
        dispatch({ type: 'SET_ASSIGNMENT_MODAL', payload: { modalOpen: true } });
    }

    handleRefresh = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_STUDENT_COURSE_WORK', payload: { studentId: match.params.id } });
    }

    render() {
        const {
            user,
            student,
            cohort,
            feedbackDataset,
            courseWork,
            notes,
            modalOpen,
            selectedAssignment,
            selectedCourseWork,
        } = this.props;

        const pageTitle = `Student :: ${student.first_name} ${student.last_name}`;

        const actions = [{
            label: 'Edit',
            // Dispatch an action...
            type: 'SET_EDITING_STUDENT',
            payload: student,
            // ...and navigate to this page.
            path: `/students/${student.id}/edit`,
        }];

        return (
            <div className="container">
                <PageTitle title={pageTitle} actions={actions} />

                <StudentProfile user={user} student={student} cohort={cohort} />
                {(student.placement_count && student.placement_count !== '0') ? <StudentPlacementTable /> : null}
                {
                    feedbackDataset
                    && feedbackDataset.datasets
                    // At least two days of feedback to show a trend
                    && feedbackDataset.datasets.length > 1
                    && <StudentFeedbackGraph feedbackDataset={feedbackDataset} />
                }
                <br />
                {
                    courseWork
                    && courseWork.course_works
                    && courseWork.course_works.length > 0
                    && (
                        <StudentCourseWork
                            viewCourseWork={this.viewCourseWork}
                            courseWork={courseWork}
                        />
                    )
                }
                <br />
                <Paper>
                    <h5>Notes</h5>
                    <Divider />
                    {
                        (
                            notes.comments
                            && notes.comments.length > 0
                        ) ? (
                                notes.comments.map(comment => (
                                    <div key={comment.id}>
                                        <br />
                                        <p>{JSON.stringify(comment.content)}</p>
                                        <p>{`By: ${comment.employee.first_name} ${comment.employee.last_name}`}</p>
                                        <br />
                                        <Divider />
                                    </div>
                                ))
                            ) : (
                                <p>No notes for this student.</p>
                            )
                    }
                </Paper>
                <CourseWorkModal
                    handleRefresh={this.handleRefresh}
                    student={student}
                    open={modalOpen}
                    assignment={selectedAssignment}
                    courseWork={selectedCourseWork}
                    closeActionType="SET_ASSIGNMENT_MODAL"
                />
            </div>
        );
    }
}

StudentDetailPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    notes: PropTypes.instanceOf(Object).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    selectedAssignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    student: state.student.student,
    feedbackDataset: state.student.feedbackDataset,
    courseWork: state.student.courseWork,
    notes: state.student.notes,
    modalOpen: state.assignments.modalOpen,
    selectedAssignment: state.assignments.selectedAssignment,
    selectedCourseWork: state.assignments.selectedCourseWork,
});

export default connect(mapStateToProps)(StudentDetailPage);

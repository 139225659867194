import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    TextField,
    Button,
    Grid,
    Paper,
} from '@material-ui/core';
import PageTitle from '../PageTitle/PageTitle';

class ResetPasswordPage extends Component {
    state = {
        username: '', // 'tester@primeacademy.io',
        password: '',
        retypePassword: '',
        errorText: '',
        statusText: '',
    };

    componentDidMount() {
        const {
            dispatch,
        } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    changePassword = (event) => {
        const { dispatch, match } = this.props;
        let userToken = '';
        if (match.params && match.params.token) {
            userToken = match.params.token;
        }
        event.preventDefault();
        const { username, password, retypePassword } = this.state;
        if (retypePassword !== password) {
            this.setState({
                errorText: 'Passwords do not match.',
            });
        } else if (password && password.length < 8) {
            this.setState({
                errorText: 'Password must be at least 8 characters.',
            });
        } else if (username && username.length > 3 && password && password.length > 7) {
            dispatch({ type: 'SET_NEW_PASSWORD', payload: { email: username, password, token: userToken } });
        } else {
            this.setState({
                errorText: 'Please enter a username and password.',
            });
        }
    } // end login

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
            [propertyName]: event.target.value,
            errorText: '',
        });
    }

    backToLogin = () => {
        window.location.href = '/#/login';
    }

    render() {
        const {
            username,
            password,
            errorText,
            statusText,
            retypePassword,
        } = this.state;
        return (
            <div className="container">
                <PageTitle title="Login" />
                <Paper>
                    <Grid container justify="center">
                        <form onSubmit={this.changePassword}>
                            <TextField
                                id="outlined-name"
                                label="Username"
                                style={{ width: '300px' }}
                                value={username}
                                onChange={this.handleInputChangeFor('username')}
                                margin="dense"
                                variant="outlined"
                            />
                            <br />
                            <TextField
                                id="outlined-uncontrolled"
                                label="Password"
                                type="password"
                                style={{ width: '300px' }}
                                value={password}
                                onChange={this.handleInputChangeFor('password')}
                                margin="dense"
                                variant="outlined"
                            />
                            <br />
                            <TextField
                                id="outlined-uncontrolled"
                                label="Retype Password"
                                type="password"
                                style={{ width: '300px' }}
                                value={retypePassword}
                                onChange={this.handleInputChangeFor('retypePassword')}
                                margin="dense"
                                variant="outlined"
                            />
                            <br />
                            <Grid
                                justify="space-between"
                                container
                                spacing={12}
                            >
                                <Button onClick={this.backToLogin}>Back to Login</Button>
                                <Button variant="contained" type="submit">Change Password</Button>
                            </Grid>
                            <br />
                            {
                                errorText && errorText !== '' && (
                                    <div style={{ color: 'red', width: '300px' }}>{errorText}</div>
                                )
                            }
                            {
                                statusText && statusText !== '' && (
                                    <div style={{ width: '300px' }}>{statusText}</div>
                                )
                            }
                        </form>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

ResetPasswordPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(ResetPasswordPage);

import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
};

const SendingModal = (props) => {
    const {
        classes,
        open,
        onClose,
    } = props;
    return (
        <Dialog open={open}>
            <DialogTitle id="responsive-dialog-title">
                Save Successful
            </DialogTitle>
            <DialogContent align="center" className={classes.feedbackContainer}>
                {/* TODO: Should there be text here? */}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SendingModal.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(SendingModal);

import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

// worker Saga: will be fired on "FETCH_EMPLOYEE_LIST" actions
function* fetchEmployeeList() {
    try {
        const response = yield axios.get('api/employees/list');
        yield put({ type: 'SET_EMPLOYEE_LIST', payload: response.data });
    } catch (error) {
        console.log('Campus get request failed', error);
    }
}

// For non-prime admission process students
function* createEmployee(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('/api/employees/register', action.payload);
        yield put({ type: 'FETCH_EMPLOYEE_LIST' });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace('/employees');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* employeeSaga() {
    yield takeLatest('FETCH_EMPLOYEE_LIST', fetchEmployeeList);
    yield takeLatest('CREATE_EMPLOYEE', createEmployee);
}

export default employeeSaga;

import moment from 'moment-timezone';

export function formatDate(dateString) {
    const d = moment.utc(dateString);
    return d.tz('US/Central').format('YYYY-MM-DD');
}

export function shortDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        const d = moment.utc(dateTimeString);
        result = d.tz('US/Central').format('MMM D [at] h:mm a');
    }
    return result;
}

export function extendedDateTime(dateTimeString) {
    let result = '';
    if (dateTimeString && dateTimeString.length > 0) {
        const d = moment.utc(dateTimeString);
        result = d.tz('US/Central').format('MMMM Do, YYYY [at] h:mm a');
    }
    return result;
}

export default {
    formatDate,
    shortDateTime,
    extendedDateTime,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Hidden,
    TableSortLabel,
    TablePagination,
    FormHelperText,
    Checkbox,
    Button,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import PageTitle from '../../PageTitle/PageTitle';
import AssignmentListRow from './AssignmentListRow';
import CreateAssignmentModal from '../../Modal/CreateAssignmentModal';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import AlertModal from '../../Modal/AlertModal';
import { cohortOptionSelect } from '../../../helpers/CohortTextHelper';
import SaveCompleteModal from '../../Modal/SaveCompleteModal';

const LIMIT = 20;
const NONE = 'none_selected';
const SOME = 'some_selected';
const ALL = 'all_selected';

const styles = theme => ({
    cohortList: {
        width: '100%',
    },
    selectContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    cohortSelect: {
        paddingTop: '9px',
        paddingRight: '10px',
        zIndex: '1000',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '350px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    searchInput: {
        width: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    pagination: {
        textAlign: 'right',
        width: '100%',
    },
    searchBar: {
        margin: 5,
        paddingRight: 15,
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    copyAssignmentBar: {
        margin: 5,
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 9,
        paddingBottom: 8,
        backgroundColor: '#AB3E0722',
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    helperText: {
        marginLeft: '12px',
    },
});

class AssignmentListPage extends Component {
    constructor(props) {
        super(props);
        this.searchTimeout = null;
        this.state = {
            displayCheckboxes: false,
            confirmationModalOpen: false,
            alertModalOpen: false,
            alertTitle: 'Alert',
            alertMessage: 'Unable to complete request.',
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'SET_ASSIGNMENT_MODAL', payload: { modalOpen: false } });
        const payload = this.getPayload();
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = null;
    }

    viewAssignmentDetails = assignment => () => {
        const { history } = this.props;
        history.push(`/assignments/${assignment.id}`);
    }

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        dispatch({ type: 'SET_ASSIGNMENT_SEARCH_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => { this.runSearch(searchText); }, 250);
    }

    runSearch = (searchText) => {
        const { dispatch } = this.props;
        // When the user changes search text, reset the offset to 0
        dispatch({ type: 'SET_ASSIGNMENT_OFFSET', payload: { offset: 0 } });
        const payload = {
            ...this.getPayload(),
            offset: 0,
            searchText,
        };
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
        this.searchTimeout = null;
    }

    handleChangePage = (event, page) => {
        const {
            dispatch,
        } = this.props;
        const offset = page * LIMIT;
        dispatch({ type: 'SET_ASSIGNMENT_OFFSET', payload: { offset } });
        const payload = {
            ...this.getPayload(),
            offset,
        };
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
    }

    handleCohortSelect = (selectedOption) => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_COHORT_SEARCH_TEXT', payload: selectedOption });
        const payload = {
            ...this.getPayload(),
            cohortId: selectedOption.value,
            offset: 0,
        };
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
    };

    // Refresh with existing search filters, called after an assignment is added
    handleRefresh = () => {
        const { dispatch } = this.props;
        const payload = this.getPayload();
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        let { order } = sortOrder;
        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const updatedSort = {
            orderBy,
            order,
        };
        dispatch({ type: 'SET_ASSIGNMENT_SORT_ORDER', payload: updatedSort });
        const payload = {
            ...this.getPayload(),
            orderBy: updatedSort.orderBy,
            order: updatedSort.order,
        };
        dispatch({ type: 'FETCH_ASSIGNMENT_LIST', payload });
    };

    getPayload = () => {
        const {
            sortOrder,
            searchText,
            selectedCohort,
            searchOffset,
        } = this.props;
        const payload = {
            order: sortOrder.order,
            orderBy: sortOrder.orderBy,
            searchText,
            cohortId: selectedCohort.value,
            offset: searchOffset,
        };
        return payload;
    }

    // Select or deselect all items in the list
    handleAllCheckbox = async (event) => {
        const {
            assignments,
            assignmentsToCopy,
            dispatch,
        } = this.props;
        event.stopPropagation();
        // TODO: Prevent the user from copying more than 200 assignments
        // TODO: Warn the user before copying more than 10 assignments
        if (assignments.rows) {
            if (assignmentsToCopy.length < assignments.count) {
                dispatch({ type: 'SELECT_ALL_ASSIGNMENTS_FOR_COHORT', payload: this.getPayload() });
            } else {
                dispatch({ type: 'DESELECT_ALL_ASSIGNMENT_CHECKBOX' });
            }
        }
    }

    handleRowCheckbox = (event, assignment) => {
        const {
            dispatch,
        } = this.props;
        event.stopPropagation();
        dispatch({ type: 'TOGGLE_ASSIGNMENT_CHECKBOX', payload: assignment });
    }

    handleCopyCohortSelect = (selectedOption) => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_COPY_INTO_COHORT', payload: selectedOption });
    }

    confirmSelectedAssignments = () => {
        const {
            assignmentsToCopy,
            copyIntoCohort,
        } = this.props;
        if (assignmentsToCopy.length > 20) {
            this.setState({ alertModalOpen: true, alertTitle: 'Too Many Assignments Selected', alertMessage: 'The maximum number of assignments you can copy is 200.' });
        } else if (copyIntoCohort.value === '') {
            this.setState({ alertModalOpen: true, alertTitle: 'Cohort Required', alertMessage: 'Please select a cohort to copy assignments into.' });
        } else {
            this.setState({ confirmationModalOpen: true });
        }
    }

    copySelectedAssignments = () => {
        const {
            dispatch,
            assignmentsToCopy,
            copyIntoCohort,
        } = this.props;
        this.setState({ displayCheckboxes: false, confirmationModalOpen: false });
        dispatch({ type: 'COPY_MANY_ASSIGNMENTS', payload: { assignmentsToCopy, cohortId: copyIntoCohort.value } });
        dispatch({ type: 'DESELECT_ALL_ASSIGNMENT_CHECKBOX' });
    }

    // Close the modal and go back
    handleSaveComplete = () => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_SENDING_ASSIGNMENT_COMPLETE', payload: { modalOpen: false } });
    }

    getSelectionStatus = () => {
        const {
            assignments,
            assignmentsToCopy,
        } = this.props;
        let status = NONE;
        if (assignmentsToCopy.length === 0) {
            status = NONE;
        } else if (assignmentsToCopy.length < assignments.count) {
            status = SOME;
        } else {
            status = ALL;
        }
        return status;
    }

    getTableHead = () => {
        const {
            displayCheckboxes,
        } = this.state;
        const {
            sortOrder,
        } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {
                        displayCheckboxes
                        && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={this.getSelectionStatus() === SOME}
                                    checked={this.getSelectionStatus() === ALL}
                                    onChange={this.handleAllCheckbox}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                            </TableCell>
                        )
                    }
                    <TableCell
                        sortDirection={sortOrder.orderBy === 'name' && sortOrder.order}
                    >
                        <TableSortLabel
                            active={sortOrder.orderBy === 'name'}
                            direction={sortOrder.order}
                            onClick={this.sortBy('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <Hidden smDown>
                        <TableCell>Cohort</TableCell>
                        <TableCell
                            sortDirection={sortOrder.orderBy === 'assignment_type' && sortOrder.order}
                        >
                            <TableSortLabel
                                active={sortOrder.orderBy === 'assignment_type'}
                                direction={sortOrder.order}
                                onClick={this.sortBy('assignment_type')}
                            >
                                Type
                            </TableSortLabel>
                        </TableCell>
                    </Hidden>
                    <Hidden smDown>
                        <TableCell>Status</TableCell>
                    </Hidden>
                    <TableCell
                        sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}
                    >
                        <TableSortLabel
                            active={sortOrder.orderBy === 'due_at'}
                            direction={sortOrder.order}
                            onClick={this.sortBy('due_at')}
                        >
                            Due At
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="right"># Completed</TableCell>
                    {/* Only three rows can fit on most mobile devices */}
                    <Hidden smDown>
                        <TableCell align="right"># Graded</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </Hidden>
                </TableRow>
            </TableHead>
        );
    }

    getSearchBar = () => {
        const {
            displayCheckboxes,
        } = this.state;
        const {
            selectedCohort,
            copyIntoCohort,
            classes,
            cohorts,
            searchText,
            assignmentsToCopy,
        } = this.props;
        const cohortOptions = cohortOptionSelect(cohorts);
        if (assignmentsToCopy && assignmentsToCopy.length > 0 && displayCheckboxes) {
            cohortOptions.unshift({ value: '', label: 'Select a cohort...' });
            return (
                <div className={classes.copyAssignmentBar}>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <span style={
                            {
                                height: 30,
                                paddingTop: 18,
                                paddingRight: 10,
                                color: '#AB3E07',
                            }
                        }
                        >
                            {`Copy ${assignmentsToCopy.length} assignments to... `}
                        </span>
                        <ReactSelect
                            placeholder="Cohort..."
                            value={copyIntoCohort}
                            onChange={this.handleCopyCohortSelect}
                            options={cohortOptions}
                            className={classes.cohortSelect}
                            classNamePrefix="react-select"
                        />
                        <Button
                            style={{ height: '37px', marginTop: '10px' }}
                            variant="outlined"
                            onClick={this.confirmSelectedAssignments}
                        >
                            Copy
                        </Button>
                        <FormControlLabel
                            style={{ flex: 1, height: '60px' }}
                            control={(
                                <Switch
                                    checked={displayCheckboxes}
                                    onChange={() => this.setState({
                                        displayCheckboxes: !displayCheckboxes,
                                    })}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            )}
                            label="Select many"
                            labelPlacement="start"
                        />
                    </div>
                </div>
            );
        }
        cohortOptions.unshift({ value: '', label: 'All Cohorts' });
        return (
            <div className={classes.searchBar}>
                <div className={classes.selectContainer} style={{ display: 'inline-block' }}>
                    <ReactSelect
                        placeholder="Cohort..."
                        value={selectedCohort}
                        onChange={this.handleCohortSelect}
                        options={cohortOptions}
                        className={classes.cohortSelect}
                        classNamePrefix="react-select"
                    />
                    <FormHelperText className={classes.helperText}>
                        Cohort select
                    </FormHelperText>
                </div>
                <TextField
                    helperText="Search by assignment name and/or tag."
                    className={classes.searchInput}
                    label="Search"
                    margin="dense"
                    variant="outlined"
                    value={searchText}
                    onChange={this.handleSearchChange}
                />
                <FormControlLabel
                    style={{ flex: 1, height: '60px', paddingTop: 17 }}
                    control={(
                        <Switch
                            checked={displayCheckboxes}
                            onChange={() => this.setState({
                                displayCheckboxes: !displayCheckboxes,
                            })}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    )}
                    label="Select many"
                    labelPlacement="start"
                />
            </div>
        );
    }

    render() {
        const {
            confirmationModalOpen,
            displayCheckboxes,
            alertModalOpen,
            alertTitle,
            alertMessage,
        } = this.state;
        const {
            selectedCohort,
            assignments,
            searchOffset,
            editModalOpen,
            assignmentsToCopy,
            copyIntoCohort,
            completeModalOpen,
        } = this.props;
        const page = searchOffset / LIMIT;
        return (
            <div className="container">
                <PageTitle
                    title="Assignments Home"
                    // Action buttons are created by the PageTitle component
                    actions={[
                        {
                            label: 'Add New Assignment',
                            // Dispatch an action...
                            type: 'UNSET_EDITING_ASSIGNMENT',
                            // ...and navigate to this page.
                            path: '/assignments/new',
                            // history.state to prepopulate the selected cohort
                            state: {
                                value: selectedCohort.id,
                                label: selectedCohort.name,
                            },
                        },
                    ]}
                />
                <Paper>
                    { this.getSearchBar() }
                    {
                        assignments
                        && assignments.rows
                        && (
                            <div>
                                <Table>
                                    {this.getTableHead()}
                                    <TableBody>
                                        {
                                            // TODO: Move this into it's own component
                                            assignments.rows.map(assignment => (
                                                <AssignmentListRow
                                                    displayCheckboxes={displayCheckboxes}
                                                    key={assignment.id}
                                                    assignment={assignment}
                                                    handleRowCheckbox={
                                                        this.handleRowCheckbox
                                                    }
                                                    viewAssignmentDetails={
                                                        this.viewAssignmentDetails
                                                    }
                                                />
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                {/* Limit could be moved to the reducer to allow
                                    rows per page changes */}
                                <TablePagination
                                    rowsPerPageOptions={[LIMIT]}
                                    component="div"
                                    count={assignments.count}
                                    rowsPerPage={LIMIT}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </div>
                        )
                    }
                </Paper>
                <CreateAssignmentModal
                    open={editModalOpen}
                    closeActionType="SET_EDIT_ASSIGNMENT_MODAL"
                    handleRefresh={this.handleRefresh}
                    selectedCohort={selectedCohort}
                />
                <ConfirmationModal
                    open={confirmationModalOpen}
                    dialogTitle="Copy Many Assignments"
                    dialogMessage={`You're about to copy ${assignmentsToCopy.length} assignments into ${copyIntoCohort.label}. Would you like to continue?`}
                    onCancel={() => this.setState({ confirmationModalOpen: false })}
                    onConfirm={() => this.copySelectedAssignments()}
                />
                <AlertModal
                    open={alertModalOpen}
                    dialogTitle={alertTitle}
                    dialogMessage={alertMessage}
                    onClose={() => this.setState({ alertModalOpen: false })}
                />
                <SaveCompleteModal
                    open={completeModalOpen}
                    onClose={this.handleSaveComplete}
                />
            </div>
        );
    }
}

AssignmentListPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    assignmentsToCopy: PropTypes.instanceOf(Array).isRequired,
    assignments: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    searchText: PropTypes.string.isRequired,
    selectedCohort: PropTypes.instanceOf(Object).isRequired,
    searchOffset: PropTypes.number.isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    editModalOpen: PropTypes.bool.isRequired,
    copyIntoCohort: PropTypes.instanceOf(Object).isRequired,
    completeModalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    assignments: state.assignments.assignmentList,
    assignmentsToCopy: state.assignments.assignmentsToCopy,
    copyIntoCohort: state.assignments.copyIntoCohort,
    selectedCohort: state.assignments.selectedCohort,
    searchText: state.assignments.searchText,
    searchOffset: state.assignments.searchOffset,
    cohorts: state.cohort.cohortList,
    sortOrder: state.assignments.sortOrder,
    editModalOpen: state.assignments.editModalOpen,
    sendingModalOpen: state.assignments.sendingModal,
    completeModalOpen: state.assignments.completeModal,
});

export default connect(mapStateToProps)(withStyles(styles)(AssignmentListPage));

import { connect } from 'react-redux';
import {
    Route,
} from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import StudentAssignments from '../StudentPortal/StudentAssignments/StudentAssignments';
import CohortListPage from '../Cohort/CohortListPage/CohortListPage';

const React = require('react');
const PropTypes = require('prop-types');
const { STUDENT, EMPLOYEE } = require('./UserTypes');

const AuthRoute = (props) => {
    const {
        user,
        Component,
        path,
        exact = false,
        requiredRoles = [],
    } = props;
    let pageToRender = Component;
    if (!user.email) {
        pageToRender = LoginPage;
    } else if (requiredRoles.includes(user.role)) {
        pageToRender = Component;
    } else if (user.role === STUDENT) {
        pageToRender = StudentAssignments;
    } else if (user.role === EMPLOYEE) {
        pageToRender = CohortListPage;
    }
    return (
        <Route
            exact={exact}
            path={path}
            component={pageToRender}
        />
    );
};

AuthRoute.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    Component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool.isRequired,
    requiredRoles: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(AuthRoute);

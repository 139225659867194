import React from 'react';
import { Line } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

const StudentDetailPage = (props) => {
    const {
        feedbackDataset,
    } = props;
    const chartOptions = {
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    tooltipFormat: 'MM/DD/YY',
                    unit: 'week',
                    displayFormats: {
                        day: 'MM/DD/YY',
                    },
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 6,
                },
            }],
        },
    };
    return (
        <Paper>
            <h5>Progress Trends</h5>
            {
                feedbackDataset.datasets
                && feedbackDataset.datasets.length > 0
                && <Line height={80} data={feedbackDataset} options={chartOptions} />
            }
        </Paper>
    );
};

StudentDetailPage.propTypes = {
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
};

export default StudentDetailPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CohortTextHelper from '../../../../helpers/CourseWorkTextHelper';
import FeedbackTooltip from '../../../General/FeedbackTooltip';

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
    },
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedback: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

class StudentCourseWork extends Component {
    state = {
        gradedOnly: false,
    }

    // setState is async, and we were losing the value of event
    // by the time setState actually ran.
    // Saving in a variable allows us to remember for the callback
    // https://www.ludofischer.com/blog/reactjs-null-event-target/
    handleChange = name => (event) => {
        const { checked } = event.target;
        this.setState(previousState => (
            { ...previousState, [name]: checked }
        ));
    }

    render() {
        const {
            courseWork,
            classes,
            viewCourseWork,
        } = this.props;
        const { gradedOnly } = this.state;

        let courseWorkToDisplay = [];
        if (courseWork && courseWork.course_works) {
            if (gradedOnly) {
                courseWorkToDisplay = courseWork.course_works.filter(work => work.assignment.assignment_type === 'graded');
            } else {
                courseWorkToDisplay = courseWork.course_works;
            }
        }

        return (
            <Paper>
                <h5>Assignments</h5>
                <FormControlLabel
                    control={
                        (
                            <Checkbox
                                checked={gradedOnly}
                                onChange={this.handleChange('gradedOnly')}
                                value="gradedOnly"
                                color="primary"
                            />
                        )
                    }
                    label="Show only graded assignments"
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Grade</TableCell>
                            <TableCell>Feedback</TableCell>
                            <TableCell>Completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            courseWorkToDisplay.map(work => (
                                <TableRow
                                    key={work.assignment.id}
                                    className={classes.tableRow}
                                    hover
                                    onClick={viewCourseWork(work, work.assignment)}
                                >
                                    <TableCell>{work.assignment.name}</TableCell>
                                    <TableCell>{work.grade}</TableCell>
                                    <TableCell>
                                        {
                                            work.instructor_comment
                                            && (
                                                // Not sure if it makes sense to have a
                                                // feedback bubble here
                                                <FeedbackTooltip
                                                    title="Instructor Comments"
                                                    tooltip={
                                                        `${work.instructor_comment.substring(0, 120)}... (click to view more)`
                                                    }
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            CohortTextHelper.shortStatusText(
                                                work,
                                                work.assignment,
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

StudentCourseWork.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    viewCourseWork: PropTypes.func.isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StudentCourseWork);

import { combineReducers } from 'redux';

// Offset for the assignment list page
const searchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_OFFSET':
        return action.payload.offset;
    case 'UNSET_ASSIGNMENT_OFFSET':
        return 0;
    default:
        return state;
    }
};

// Search text for the assignment list page
const searchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_SEARCH_TEXT':
        return action.payload.text;
    case 'UNSET_ASSIGNMENT_SEARCH_TEXT':
        return '';
    default:
        return state;
    }
};

// orderBy db column name, order is asc or desc
const defaultSortOrder = { orderBy: 'due_at', order: 'desc' };

// Sort order for the assignment list page
const sortOrder = (state = defaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_SORT_ORDER':
        return action.payload;
    case 'UNSET_ASSIGNMENT_SORT_ORDER':
        return defaultSortOrder;
    default:
        return state;
    }
};

// Cohort selected on the assignment page
const selectedCohort = (state = { value: '', label: 'All Cohorts' }, action) => {
    switch (action.type) {
    case 'SET_COHORT_SEARCH_TEXT':
        return action.payload;
    case 'UNSET_COHORT_SEARCH_TEXT':
        return { value: '', label: 'All Cohorts' };
    default:
        return state;
    }
};

// Cohort to prepoulate on the new assignment page
const newAssignmentCohort = (state = { value: '', label: 'All Cohorts' }, action) => {
    switch (action.type) {
    case 'SET_NEW_ASSIGNMENT_COHORT':
        return action.payload;
    case 'UNSET_NEW_ASSIGNMENT_COHORT':
        return { value: '', label: 'All Cohorts' };
    default:
        return state;
    }
};

// Cohort to copy multiple assignments into
const copyIntoCohort = (state = { value: '', label: 'Select a cohort...' }, action) => {
    switch (action.type) {
    case 'SET_COPY_INTO_COHORT':
        return action.payload;
    case 'UNSET_COPY_INTO_COHORT':
        return { value: '', label: 'Select a cohort...' };
    default:
        return state;
    }
};

const assignmentsToCopy = (state = [], action) => {
    let copy = [];
    switch (action.type) {
    case 'SELECT_ALL_ASSIGNMENT_CHECKBOX':
        copy = action.payload.map(item => item.id);
        return copy;
    case 'DESELECT_ALL_ASSIGNMENT_CHECKBOX':
        return [];
    case 'TOGGLE_ASSIGNMENT_CHECKBOX':
        if (state.indexOf(action.payload.id) >= 0) {
            // The id exists, remove it
            copy = state.filter((item) => {
                if (item === action.payload.id) {
                    return false;
                }
                return true;
            });
            return copy;
        }
        return [...state, action.payload.id];
    case 'UNSET_ASSIGNMENT_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

// Assignments that appear on the assignmnt list page
const assignmentList = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_LIST':
        return action.payload;
    case 'UNSET_ASSIGNMENT_LIST':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

// Assignment that appears on the assignment detail page
const assignment = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT':
        return action.payload;
    case 'UNSET_ASSIGNMENT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

// List of course work for the selected assignment
const courseWorkList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COURSE_WORK_LIST':
        return action.payload;
    case 'UNSET_COURSE_WORK_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const assignModalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_ASSIGN_ASSIGNMENT_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

// --- START COURSE WORK MODAL REDUCERS ---

// Modal that appears on the assignment page
const modalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

// Used to display student information within the modal that displays
// course work.
const selectedStudent = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_STUDENT':
        return action.payload;
    case 'UNSET_ASSIGNMENT_STUDENT':
        return {};
    default:
        return state;
    }
};

// Course work turned in for a specific assignment. This is displayed
// in a modal when individual course work is clicked on from the
// assignment detail view.
const selectedCourseWork = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_COURSE_WORK':
        return action.payload;
    case 'UNSET_ASSIGNMENT_COURSE_WORK':
        return {};
    default:
        return state;
    }
};

// Assignment that appears in the modal. It's possible that through navigation,
// the assignment in the modal could end up being different from the assignement on
// the detail page. This could be avoided by prompting the user before navigation
// jumps.
const selectedAssignment = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ASSIGNMENT_SELECTED':
        return action.payload;
    case 'UNSET_ASSIGNMENT_SELECTED':
        return {};
    default:
        return state;
    }
};

// --- END COURSE WORK MODAL REDUCERS ---

// --- START EDIT ASSIGNMENT MODAL REDUCERS ---

const defaultAssignment = {
    name: '',
    type: 'not_graded',
};
// Used to store fields when assignments are created or edited
const editingAssignment = (state = defaultAssignment, action) => {
    let copy = {};
    switch (action.type) {
    case 'SET_EDITING_ASSIGNMENT':
        copy = Object.assign({}, action.payload);
        if (copy.tags) {
            copy.tags = copy.tags.map(tag => (
                { ...tag, value: tag.id, label: tag.name }
            ));
        }
        // If a cohort exists, make sure the value and label are available
        // for the drop down select to pre-populate.
        if (copy.cohort && copy.cohort.id) {
            copy.cohort = { ...copy.cohort, value: copy.cohort.id, label: copy.cohort.name };
        }
        return copy;
    case 'COPY_ASSIGNMENT':
        copy = Object.assign({}, action.payload);
        delete copy.cohortId;
        delete copy.cohort;
        if (copy.tags) {
            copy.tags = copy.tags.map(tag => (
                { ...tag, value: tag.id, label: tag.name }
            ));
        }
        return copy;
    case 'UNSET_EDITING_ASSIGNMENT':
        return defaultAssignment;
    default:
        return state;
    }
};

// Displayed when we get back a success
const completeModal = (state = false, action) => {
    switch (action.type) {
    case 'SET_SENDING_ASSIGNMENT_COMPLETE':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

// Whether or not the edit / create assignment modal is open
const editModalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_EDIT_ASSIGNMENT_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

// --- END EDIT ASSIGNMENT MODAL REDUCERS ---

const tags = (state = [], action) => {
    switch (action.type) {
    case 'SET_TAG_LIST':
        return action.payload.map(item => (
            {
                ...item,
                label: item.name,
                value: item.id,
            }
        ));
    default:
        return state;
    }
};

export default combineReducers({
    searchOffset,
    searchText,
    assignment,
    assignmentList,
    courseWorkList,
    selectedCourseWork,
    selectedAssignment,
    modalOpen,
    selectedStudent,
    selectedCohort,
    newAssignmentCohort,
    sortOrder,
    editingAssignment,
    editModalOpen,
    assignModalOpen,
    completeModal,
    tags,
    assignmentsToCopy,
    copyIntoCohort,
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    List,
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PageTitle from '../../PageTitle/PageTitle';
import CohortStudentRow from './CohortStudentRow';
import CohortSummary from './CohortSummary';

const styles = {
    cohortList: {
        width: '100%',
    },
};

class CohortStudentsPage extends Component {
    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    getPlacementText = (student) => {
        let result = '';
        if (student.placement_count > 0 || student.application_status === 'placed') {
            result = <span className="status-text status-placed">Placed</span>;
        } else if (student.skip_placement_reporting) {
            result = <span className="status-text status-excluded">Excluded</span>;
        } else if (student.application_status === 'dropped_out') {
            result = <span className="status-text status-unplaced">Dropped Out</span>;
        } else {
            result = <span className="status-text status-unplaced">Unplaced</span>;
        }
        return result;
    }

    viewStudentDetails = student => () => {
        const { history } = this.props;
        history.push(`/students/${student.id}`);
    }

    render() {
        // const { students } = this.props;
        const { user, cohort, classes } = this.props;
        const actionButtons = [
            {
                label: 'Edit',
                // Dispatch an action...
                type: 'SET_EDITING_COHORT',
                payload: cohort,
                // ...and navigate to this page.
                path: `/cohorts/${cohort.id}/edit`,
            },
        ];
        return (
            <div className="container">
                <PageTitle
                    title={`Cohort :: ${cohort.name}`}
                    // Action buttons are created by the PageTitle component
                    actions={actionButtons}
                    navigation={
                        [
                            { name: 'details', path: `/cohorts/${cohort.id}`, selected: true },
                            { name: 'assignments', path: `/cohorts/${cohort.id}/assignments` },
                            { name: 'feedback', path: `/cohorts/${cohort.id}/feedback` },
                        ]
                    }
                />
                <CohortSummary cohort={cohort} />
                <Paper>
                    <List className={classes.cohortList}>
                        {
                            cohort.students
                            && cohort.students.map(student => (
                                <CohortStudentRow
                                    key={student.id}
                                    user={user}
                                    student={student}
                                    getPlacementText={this.getPlacementText}
                                    viewStudentDetails={this.viewStudentDetails}
                                />
                            ))
                        }
                    </List>
                </Paper>
            </div>
        );
    }
}

CohortStudentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    students: state.cohort.cohortStudentList,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortStudentsPage));

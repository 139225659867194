import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Paper, Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const styles = {
    secondaryNavigation: {
        padding: '0px 15px 15px 15px',
    },
    navigationLink: {
        paddingRight: '10px',
    },
    spacer: {
        flex: 1,
    },
    content: {
        padding: '12px 0px 12px 0px',
        // margin: '0px 15px',
        display: 'flex',
        alignItems: 'center',
    },
    actionButton: {
        margin: '0px 15px 0px 0px',
    },
};

const PageTitle = ({
    title,
    navigation,
    classes,
    actions,
    dispatch,
    history,
}) => (
    <Paper className="page-title" square elevation={1}>
        <div className={classes.content}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <h3 className="page-title-padding">{title}</h3>
            <div className={classes.spacer} />
            {
                actions
                && actions.length > 0
                && (
                    <>
                        {actions.map(action => (
                            <Button
                                key={action.label}
                                className={classes.actionButton}
                                variant={action.variant || 'outlined'}
                                color={action.color || 'default'}
                                type="button"
                                onClick={() => {
                                    dispatch(action);
                                    if (action.path) {
                                        history.push(action.path, action.state);
                                    }
                                }}
                            >
                                {action.label}
                            </Button>
                        ))}
                    </>
                )
            }
        </div>
        {
            navigation
            && navigation.length > 0
            && (
                <div className={classes.secondaryNavigation}>
                    {navigation.map(item => (
                        item.selected ? (
                            <span
                                key={item.name}
                                className={classes.navigationLink}
                            >
                                {item.name}
                            </span>
                        ) : (
                            <Link
                                replace
                                key={item.name}
                                className={classes.navigationLink}
                                to={item.path}
                            >
                                {item.name}
                            </Link>
                        )
                    ))}
                </div>
            )
        }
    </Paper>
);

PageTitle.defaultProps = {
    navigation: [],
    actions: [],
};

PageTitle.propTypes = {
    dispatch: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    navigation: PropTypes.instanceOf(Array),
    actions: PropTypes.instanceOf(Array),
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
};

export default connect()(withStyles(styles)(withRouter(PageTitle)));

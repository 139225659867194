import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

// worker Saga: will be fired on "FETCH_CAMPUS_LIST" actions
function* fetchCampusList() {
    try {
        const response = yield axios.get('api/campuses');
        yield put({ type: 'SET_CAMPUS_LIST', payload: response.data });
    } catch (error) {
        console.log('Campus get request failed', error);
    }
}

function* userSaga() {
    yield takeLatest('FETCH_CAMPUS_LIST', fetchCampusList);
}

export default userSaga;

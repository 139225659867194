import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Hidden,
    TableBody,
} from '@material-ui/core';
import PageTitle from '../../PageTitle/PageTitle';
import StudentAssignmentRow from './StudentAssignmentRow';

class StudentAssignmentsPage extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    showCourseWork = (selectedCourseWork) => {
        const { history } = this.props;
        history.push(`/student/assignments/${selectedCourseWork.assignment.id}`);
    }

    render() {
        const { assignments } = this.props;

        return (
            <div className="container">
                <PageTitle
                    title="Assignments"
                />
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <Hidden smDown>
                                    <TableCell>Submission</TableCell>
                                </Hidden>
                                <TableCell>Feedback</TableCell>
                                <TableCell>Due Date</TableCell>
                                <Hidden smDown>
                                    <TableCell align="right">Action</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                assignments.map(courseWork => (
                                    <StudentAssignmentRow
                                        key={courseWork.id}
                                        courseWork={courseWork}
                                        viewAssignmentDetails={this.showCourseWork}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

StudentAssignmentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    assignments: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    assignments: state.studentPortal.assignments,
});

export default connect(mapStateToProps)(StudentAssignmentsPage);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Hidden,
    Grid,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CodeBlock from '../../General/CodeBlock';
import PageTitle from '../../PageTitle/PageTitle';
import CourseWorkModal from '../../Modal/CourseWorkModal';
import AssignmentDetailRow from './AssignmentDetailRow';
import AssignAssignmentModal from '../../Modal/AssignAssignmentModal';
import { extendedDateTime } from '../../../helpers/DateFormatHelper';


const styles = {
    textField: {
        fontSize: '12px',
    },
};

class AssignmentPage extends Component {
    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_ASSIGNMENT' });
        dispatch({ type: 'UNSET_COURSE_WORK_LIST' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: match.params.id } });
    }

    viewCourseWork = student => () => {
        const { dispatch, assignment } = this.props;
        if (student.course_works && student.course_works.length > 0) {
            dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: student.course_works[0] });
        } else {
            dispatch({ type: 'UNSET_ASSIGNMENT_COURSE_WORK' });
        }
        dispatch({ type: 'SET_ASSIGNMENT_STUDENT', payload: student });
        dispatch({ type: 'SET_ASSIGNMENT_SELECTED', payload: assignment });
        dispatch({ type: 'SET_ASSIGNMENT_MODAL', payload: { modalOpen: true } });
    }

    handleRefresh = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: match.params.id } });
    }

    getGradeForCourseWork = (courseWork) => {
        let result = 'Not Graded';
        if (courseWork.workflow_state === 'graded') {
            result = courseWork.grade;
        }
        return result;
    }

    //  For Copying, when we get there.
    // copyToClipboard = (e) => {
    //     console.log(this.textArea)
    //     this.textArea.select();
    //     document.execCommand('copy');
    //     // This is just personal preference.
    //     // I prefer to not show the the whole text area selected.
    //     e.target.focus();
    //     this.setState({ copySuccess: 'Copied!' });
    // };

    render() {
        const {
            modalOpen,
            courseWorkList,
            selectedStudent,
            selectedCourseWork,
            assignment,
            assignModalOpen,
            classes,
        } = this.props;

        // Used when tapping the copy button. Set the parentId of the new
        // assignment to be this assignment.
        const copyAssignment = Object.assign({}, assignment);
        copyAssignment.parentId = copyAssignment.id;
        delete copyAssignment.id;
        delete copyAssignment.slug;

        const actionButtons = [
            {
                label: 'Edit',
                // Dispatch an action...
                type: 'SET_EDITING_ASSIGNMENT',
                payload: assignment,
                // ...and navigate to this page.
                path: `/assignments/${assignment.id}/edit`,
            },
            {
                label: 'Copy',
                // Dispatch an action...
                type: 'COPY_ASSIGNMENT',
                payload: copyAssignment,
                // ...and navigate to this page.
                path: '/assignments/new',
            },
        ];
        // TODO: Add modal to select due date when assigned.
        if (assignment.workflow_state === 'assigned') {
            actionButtons.unshift({ label: 'Unassign', type: 'UNASSIGN_ASSIGNMENT', payload: assignment });
        } else {
            actionButtons.unshift({ label: 'Assign', type: 'SET_ASSIGN_ASSIGNMENT_MODAL', payload: { modalOpen: true } });
        }

        return (
            <div className="container">
                <PageTitle
                    title={`Assignment :: ${assignment.name}`}
                    // Action buttons are created by the PageTitle component
                    actions={actionButtons}
                />
                {
                    assignment.cohort
                    && (
                        <Paper>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item xs={6}>
                                    <strong>Cohort:</strong>
                                    <br />
                                    <Link to={`/cohorts/${assignment.cohort.id}`}>{assignment.cohort.name}</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <strong>Type:</strong>
                                    <br />
                                    {
                                        assignment.assignment_type === 'graded' ? (
                                            <p>Graded</p>
                                        ) : (
                                            <p>Not Graded</p>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={6}>

                                    <strong>Assigned at:</strong>
                                    <br />
                                    {
                                        assignment.workflow_state === 'assigned' ? (
                                            <p>{extendedDateTime(assignment.assigned_at)}</p>
                                        ) : (
                                            <p>Not Assigned</p>
                                        )
                                    }

                                </Grid>
                                <Grid item xs={6}>

                                    <strong>Due at:</strong>
                                    <br />
                                    <p>{extendedDateTime(assignment.due_at)}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <strong>Tags:</strong>
                                    <div>
                                        {
                                            assignment.tags
                                            && assignment.tags.map((tag, i, arr) => {
                                                let result = <span key={tag.name}>{`${tag.name}`}</span>;
                                                if (i < arr.length - 1) {
                                                    result = <span key={tag.name}>{`${tag.name}, `}</span>;
                                                }
                                                return result;
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <strong>Git Repo:</strong>
                                        <br />
                                        <a href={assignment.repository} target="_blank" rel="noopener noreferrer">{assignment.repository}</a>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* TODO: Copy to Clipboard
                                        This is close. Add it to TextField as props. The ref
                                        isnt working as expected, If this element were a textarea
                                        instead, it would work. No proof, but I suspect MUI
                                // InputProps={{
                                //     // ref:(textarea) => this.textArea = textarea,
                                //     startAdornment: (
                                //         <InputAdornment position="end">
                                //             {document.queryCommandSupported('copy') &&
                                //                 <span>
                                //                     <IconButton onClick={this.copyToClipboard}>
                                //                         <Assignment />
                                //                     </IconButton>
                                //                     {this.state.copySuccess}
                                //                 </span>}
                                //         </InputAdornment>
                                //     ),
                                // }} >
                                        */}
                                    <TextField
                                        fullWidth
                                        label="Student Assignment URL"
                                        value={`https://primeacademy.io/student/assignments/${assignment.slug}`}
                                        InputProps={{
                                            classes: {
                                                input: classes.textField,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <strong>Average grade:</strong>
                                    <p>{Number(assignment.summary.averageScore).toFixed(2)}</p>
                                </Grid>
                            </Grid>


                        </Paper>
                    )
                }
                <Paper>
                    <h5>Assignment Notes</h5>
                    <ReactMarkdown
                        align="left"
                        source={assignment.notes}
                        renderers={{ code: CodeBlock }}
                    />
                </Paper>
                <Paper>
                    <h5>Course Work</h5>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {
                                    assignment.assignment_type === 'graded'
                                    && (
                                        <>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Graded By</TableCell>
                                        </>
                                    )
                                }
                                <TableCell>Feedback</TableCell>
                                <Hidden smDown>
                                    <TableCell>Completed At</TableCell>
                                </Hidden>
                                <TableCell align="right">Completed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                courseWorkList
                                && courseWorkList.map(student => (
                                    <AssignmentDetailRow
                                        key={student.id}
                                        student={student}
                                        assignment={assignment}
                                        viewCourseWork={this.viewCourseWork}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <AssignAssignmentModal
                    assignment={assignment}
                    open={assignModalOpen}
                    closeActionType="SET_ASSIGN_ASSIGNMENT_MODAL"
                />
                <CourseWorkModal
                    student={selectedStudent}
                    courseWork={selectedCourseWork}
                    open={modalOpen}
                    handleRefresh={this.handleRefresh}
                    assignment={assignment}
                    closeActionType="SET_ASSIGNMENT_MODAL"
                />
            </div>
        );
    }
}

AssignmentPage.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    courseWorkList: PropTypes.instanceOf(Array).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    selectedStudent: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    assignModalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    courseWorkList: state.assignments.courseWorkList,
    assignment: state.assignments.assignment,
    selectedStudent: state.assignments.selectedStudent,
    selectedCourseWork: state.assignments.selectedCourseWork,
    modalOpen: state.assignments.modalOpen,
    assignModalOpen: state.assignments.assignModalOpen,
});

export default withStyles(styles)(connect(mapStateToProps)(AssignmentPage));

import { combineReducers } from 'redux';

// Employee list used to populate drop down menus
const employeeList = (state = [], action) => {
    switch (action.type) {
    case 'SET_EMPLOYEE_LIST':
        return action.payload;
    case 'UNSET_EMPLOYEE_LIST':
        return [];
    default:
        return state;
    }
};

const defaultEmployee = {
    first_name: '',
    last_name: '',
    email: '',
};

const editingEmployee = (state = defaultEmployee, action) => {
    switch (action.type) {
    case 'SET_EDITING_EMPLOYEE':
        return action.payload;
    case 'UNSET_EDITING_STUDENT':
    case 'UNSET_USER':
        return defaultEmployee;
    default:
        return state;
    }
};

export default combineReducers({
    employeeList,
    editingEmployee,
});

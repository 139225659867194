import React from 'react';
import {
    Button,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
    Hidden,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { shortDateTime, extendedDateTime } from '../../../helpers/DateFormatHelper';
import FeedbackTooltip from '../../General/FeedbackTooltip';
import { studentStatusText } from '../../../helpers/CourseWorkTextHelper';

const styles = {
    actionButtons: {
        marginLeft: '15px',
    },
    assignmentStatus: {
        padding: '6px 0px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

const gradeText = (courseWork) => {
    let result = '--';
    if (courseWork.assignment
        && courseWork.assignment.assignment_type === 'not_graded'
        && courseWork.instructor_comment
        && courseWork.completed_at) {
        result = 'Complete';
    } else {
        switch (courseWork.grade) {
        case 1:
            result = 'Reinforcement Needed';
            break;
        case 3:
            result = 'Meets Expectations';
            break;
        case 5:
            result = 'Exceeds Expectations';
            break;
        default:
            result = '--';
        }
    }
    return result;
};

const CohortAssignmentRow = ({
    courseWork,
    viewAssignmentDetails,
    classes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={() => viewAssignmentDetails(courseWork)}
    >
        <TableCell>
            <p>
                {courseWork.assignment.name}
            </p>
            <Hidden mdUp>
                <p className={classes.assignmentStatus}>
                    {
                        studentStatusText(
                            courseWork,
                            courseWork.assignment,
                        )
                    }
                </p>
            </Hidden>
        </TableCell>
        <Hidden smDown>
            <TableCell>
                {
                    studentStatusText(
                        courseWork,
                        courseWork.assignment,
                    )
                }
            </TableCell>
        </Hidden>
        <TableCell>
            {gradeText(courseWork)}
            {
                courseWork.instructor_comment
                && (
                    // Not sure if it makes sense to have a feedback bubble here
                    <FeedbackTooltip
                        title="Instructor Comments"
                        tooltip={
                            `${courseWork.instructor_comment.substring(0, 120)}... (click to view more)`
                        }
                    />
                )
            }
        </TableCell>
        <TableCell>
            {/* On hover, show the extended date / time */}
            <Tooltip
                title={(
                    <React.Fragment>
                        <Typography color="inherit">
                            {extendedDateTime(courseWork.assignment.due_at)}
                        </Typography>
                    </React.Fragment>
                )}
            >
                <p>{shortDateTime(courseWork.assignment.due_at)}</p>
            </Tooltip>
        </TableCell>
        <Hidden smDown>
            <TableCell align="right">
                <div>
                    <Button variant="outlined" onClick={() => viewAssignmentDetails(courseWork)} type="button">View</Button>
                </div>
            </TableCell>
        </Hidden>
    </TableRow>
);

CohortAssignmentRow.propTypes = {
    courseWork: PropTypes.instanceOf(Object).isRequired,
    viewAssignmentDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortAssignmentRow);

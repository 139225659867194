import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: 200, // '100%',
        marginRight: '10px',
    },
    helperText: {
        marginTop: '6px',
        marginLeft: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    formContainer: {
        overflow: 'hidden',
        marginBottom: '10px',
    },
};

const AssignAssignmentModal = (props) => {
    const {
        classes,
        open,
        fullScreen,
        dispatch,
        closeActionType,
        assignment,
    } = props;

    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [dueAt, setDueAt] = useState('');

    const handleDateChange = event => setDueDate(event.target.value);
    const handleTimeChange = event => setDueTime(event.target.value);

    // Used to set the initial date from props.
    useEffect(() => {
        if (!assignment || !assignment.due_at) {
            return;
        }
        const dueAtFromAssignment = moment.utc(assignment.due_at).tz('US/Central');
        let dateString = '';
        if (dueAtFromAssignment.isValid) {
            dateString = dueAtFromAssignment.format('YYYY-MM-DD HH:mm');
        }
        // Date string has not changed, don't update the UI.
        if (dueAt === dateString) {
            return;
        }
        setDueAt(dueAtFromAssignment.format('YYYY-MM-DD HH:mm'));
        const dueDateFromProps = dueAtFromAssignment.format('YYYY-MM-DD');
        setDueDate(dueDateFromProps);
        const dueTimeFromProps = dueAtFromAssignment.format('HH:mm');
        setDueTime(dueTimeFromProps);
    }, [assignment, dueTime, dueDate, dueAt]);

    // Very carefully modify state within a render.
    if (dueDate !== '' && dueTime === '') {
        // We must have a time if a date is set.
        setDueTime('08:00');
    }

    return (
        <Dialog fullScreen={fullScreen} open={open}>
            <DialogTitle id="responsive-dialog-title">
                {assignment.name}
            </DialogTitle>
            <DialogContent align="left" className={classes.feedbackContainer}>
                <h5>Due Date</h5>
                <br />
                <form className={classes.formContainer} noValidate>
                    <TextField
                        id="date"
                        label="Date:"
                        type="date"
                        value={dueDate}
                        onChange={handleDateChange}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="time"
                        label="Time:"
                        type="time"
                        value={dueTime}
                        onChange={handleTimeChange}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </form>
                <span className={classes.helperText}>Leave blank for no due date.</span>
            </DialogContent>
            <br />
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch({
                            type: closeActionType,
                            payload: {
                                modalOpen: false,
                            },
                        });
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const dateTime = moment(`${dueDate} ${dueTime}`, 'YYYY-MM-DD HH:mm', true);
                        const assignmentPayload = { id: assignment.id };
                        if (dateTime.isValid()) {
                            assignmentPayload.dueAt = dateTime.format();
                        } else {
                            assignmentPayload.dueAt = undefined;
                        }
                        // Assign the assignment
                        dispatch({
                            type: 'ASSIGN_ASSIGNMENT',
                            payload: assignmentPayload,
                        });
                        // Close the modal
                        dispatch({
                            type: closeActionType,
                            payload: {
                                modalOpen: false,
                            },
                        });
                    }}
                >
            Assign
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AssignAssignmentModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    closeActionType: PropTypes.string.isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
};

const assignModal = withMobileDialog()(withStyles(styles)(AssignAssignmentModal));
export default connect()(assignModal);

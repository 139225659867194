import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    Hidden,
    TablePagination,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PageTitle from '../../PageTitle/PageTitle';

const LIMIT = 20;

const styles = {
    cohortList: {
        width: '100%',
    },
    searchInput: {
        width: '350px',
    },
    tableRow: {
        cursor: 'pointer',
    },
    pagination: {
        textAlign: 'right',
        width: '100%',
    },
};

class CohortList extends Component {
    constructor(props) {
        super(props);
        this.searchTimeout = null;
    }

    componentDidMount() {
        const { dispatch, searchText, searchOffset } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset: searchOffset } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = null;
    }

    viewStudentDetails = student => () => {
        const { dispatch } = this.props;
        const action = { type: 'SET_STUDENT', payload: student };
        dispatch(action);
        const { history } = this.props;
        history.push(`/students/${student.id}`);
    }

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        dispatch({ type: 'SET_SEARCH_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => { this.runSearch(searchText); }, 250);
    }

    runSearch = (searchText) => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_SEARCH_OFFSET', payload: { offset: 0 } });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset: 0 } });
        this.searchTimeout = null;
    }

    handleChangePage = (event, page) => {
        const {
            dispatch,
            searchText,
        } = this.props;
        const offset = page * LIMIT;
        dispatch({ type: 'SET_SEARCH_OFFSET', payload: { offset } });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
    }

    render() {
        const {
            studentList,
            classes,
            searchText,
            searchOffset,
        } = this.props;
        const page = searchOffset / LIMIT;
        let actions;
        if (process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === 'false'
            || process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === false) {
            actions = [{
                label: 'Add New Student',
                // Dispatch an action...
                type: 'UNSET_EDITING_STUDENT',
                // ...and navigate to this page.
                path: '/students/new',
            }];
        }

        return (
            <div className="container">
                <PageTitle
                    title="Students Home"
                    actions={actions}
                />
                <Paper>
                    <div className="search-bar">
                        <TextField
                            helperText="Search by first name, last name, email or cohort."
                            className={classes.searchInput}
                            label="Search"
                            margin="dense"
                            variant="outlined"
                            value={searchText}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Cohort</TableCell>
                                <Hidden smDown>
                                    <TableCell align="right">Action</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentList
                                && studentList.rows
                                && studentList.rows.map(student => (
                                    <TableRow
                                        className={classes.tableRow}
                                        onClick={this.viewStudentDetails(student)}
                                        hover
                                        key={student.id}
                                    >
                                        <TableCell>{student.email}</TableCell>
                                        <TableCell>{`${student.first_name} ${student.last_name}`}</TableCell>
                                        <TableCell>{student.cohort_name}</TableCell>
                                        <Hidden smDown>
                                            <TableCell align="right">
                                                <Button variant="outlined" onClick={this.viewStudentDetails(student)}>View</Button>
                                            </TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    {/* Limit could be moved to the reducer to allow
                                    rows per page changes */}
                    {
                        studentList
                        && studentList.count
                        && (
                            <TablePagination
                                rowsPerPageOptions={[LIMIT]}
                                component="div"
                                count={studentList.count}
                                rowsPerPage={LIMIT}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        )
                    }
                </Paper>
            </div>
        );
    }
}

CohortList.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    searchText: PropTypes.string.isRequired,
    searchOffset: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    studentList: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    studentList: state.student.studentList,
    searchText: state.student.searchText,
    searchOffset: state.student.searchOffset,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortList));

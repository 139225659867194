import React from 'react';
import {
    TableCell,
    TableRow,
    Hidden,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FeedbackTooltip from '../../General/FeedbackTooltip';
import PhotoHelper from '../../../helpers/PhotoHelper';

const { getPhotoUrl } = PhotoHelper;

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
    },
    smallIcon: {
        margin: 0,
        padding: '0 4px',
        fontSize: '14px',
    },
    studentTitleText: {
        padding: '5px 10px',
    },
    feedbackScore: {
        fontSize: '16px',
        textAlign: 'center',
        minWidth: '80px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
    userColumn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    wideColumn: {
        minWidth: '150px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

/**
 * Feedback is returned from the server can be null. This function
 * checks to see if an entry exists and format the entry accordingly.
 */
function formatFeedbackNumber(entry) {
    let result = '';
    if (entry) {
        result = `${Number(entry).toFixed(0)}`;
    }
    return result;
}

function formatFeedbackText(entry) {
    let result = '';
    if (entry) {
        result = entry;
    }
    return result;
}

const CohortFeedbackRow = ({
    user,
    studentFeedback,
    viewFeedbackDetails,
    classes,
    index,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={viewFeedbackDetails(studentFeedback, index)}
    >
        <TableCell className={classes.wideColumn}>
            <div className={classes.userColumn}>
                <img
                    className="placeholder-photo-xs"
                    src={getPhotoUrl(user.s3_bucket, studentFeedback.student_id, studentFeedback.photo_file_name, 'thumb')}
                    alt={studentFeedback.first_name}
                />
                <p className={classes.studentTitleText}>{`${studentFeedback.first_name} ${studentFeedback.last_name}`}</p>
            </div>
            <Hidden mdUp>
                <br />
                <div className={classes.scoreContainer}>
                    {
                        studentFeedback.feeling_explanation
                        && studentFeedback.feeling_explanation.length > 0
                        && (
                            <div>
                                <p className={classes.feedbackScore}>
                                    {formatFeedbackText(studentFeedback.feeling_explanation)}
                                </p>
                                <p className="text-center">
                                    One Word
                                </p>
                            </div>
                        )
                    }
                    <div>
                        <p className={classes.feedbackScore}>
                            {formatFeedbackNumber(studentFeedback.understanding_score)}
                        </p>
                        <p className="text-center">
                            Understanding
                            <FeedbackTooltip
                                title="Understanding"
                                tooltip={
                                    formatFeedbackText(studentFeedback.understanding_explanation)
                                }
                            />
                        </p>
                    </div>
                    <div>
                        <p className={classes.feedbackScore}>
                            {formatFeedbackNumber(studentFeedback.support_score)}
                        </p>
                        <p className="text-center">
                            Support
                            <FeedbackTooltip
                                title="Support"
                                tooltip={formatFeedbackText(studentFeedback.support_explanation)}
                            />
                        </p>
                    </div>
                </div>
                <div>
                    {
                        studentFeedback.thanks
                        && studentFeedback.thanks.length > 0
                        && (
                            <div>
                                <br />
                                <strong>Thanks</strong>
                                <div>
                                    {formatFeedbackText(studentFeedback.thanks)}
                                </div>
                            </div>
                        )
                    }
                    {
                        studentFeedback.accomplishment
                        && studentFeedback.accomplishment.length > 0
                        && (
                            <div>
                                <br />
                                <strong>Accomplishment</strong>
                                <div>
                                    {formatFeedbackText(studentFeedback.accomplishment)}
                                </div>
                            </div>
                        )
                    }
                    {
                        studentFeedback.recommended_experiences
                        && studentFeedback.recommended_experiences.length > 0
                        && (
                            <div>
                                <br />
                                <strong>Suggest</strong>
                                <div>
                                    {formatFeedbackText(studentFeedback.recommended_experiences)}
                                </div>
                            </div>
                        )
                    }
                </div>
            </Hidden>
        </TableCell>
        <Hidden smDown>
            <TableCell>
                {formatFeedbackNumber(studentFeedback.understanding_score)}
                <FeedbackTooltip
                    title="Understanding"
                    tooltip={formatFeedbackText(studentFeedback.understanding_explanation)}
                />
            </TableCell>
            <TableCell>
                {formatFeedbackNumber(studentFeedback.support_score)}
                <FeedbackTooltip
                    title="Support"
                    tooltip={formatFeedbackText(studentFeedback.support_explanation)}
                />
            </TableCell>
            <TableCell>
                {formatFeedbackText(studentFeedback.feeling_explanation)}
            </TableCell>
            <TableCell>
                <FeedbackTooltip
                    title="Thanks"
                    tooltip={formatFeedbackText(studentFeedback.thanks)}
                />
            </TableCell>
            <TableCell>
                <FeedbackTooltip
                    title="Suggest"
                    tooltip={formatFeedbackText(studentFeedback.recommended_experiences)}
                />
            </TableCell>
            <TableCell>
                {formatFeedbackText(studentFeedback.accomplishment)}
            </TableCell>
        </Hidden>
    </TableRow>
);

CohortFeedbackRow.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    studentFeedback: PropTypes.instanceOf(Object).isRequired,
    viewFeedbackDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    index: PropTypes.number.isRequired,
};

export default withStyles(styles)(CohortFeedbackRow);

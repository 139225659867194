import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    List,
    ListItem,
    Icon,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';

const drawerWidth = 200;

const styles = theme => ({
    sideNav: {
        padding: '0',
        height: '100%', /* 100% Full-height */
        backgroundColor: '#222',
        overflowX: 'hidden', /* Disable horizontal scroll */
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    betaText: {
        // backgroundColor: theme.palette.primary.main,
        padding: '0px 22px',
        width: '125px',
        textAlign: 'center',
    },
    logo: {
        width: '125px',
        height: 'auto',
        padding: '30px 23px 10px 23px',
    },
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
        height: 'auto',
        width: 'auto',
    },
    navText: {
        margin: 0,
        padding: 0,
        color: 'white',
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
});

const PORTAL_FEEDBACK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdzS4-uqN_XqRwJWkeRGs_KwP-04g87NYarQlT2Lx6rialjuw/viewform';

const EmployeeNav = ({
    classes,
    handleNavClick,
    handleNavClickExternal,
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <div className={classes.sideNav}>
            <Logo className={classes.logo} />
            <List>
                <ListItem onClick={handleNavClick('/students')} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-users')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="Students" />
                </ListItem>
                <ListItem onClick={handleNavClick('/cohorts')} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-university')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="Cohorts" />
                </ListItem>
                <ListItem onClick={handleNavClick('/assignments')} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-code')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="Assignments" />
                </ListItem>
                <ListItem onClick={handleNavClick('/profile')} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-user')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="Profile" />
                </ListItem>
                <ListItem onClick={handleClick} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-cog')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="Settings" />
                    {open ? (
                        <Icon
                            className={classNames(classes.icon, 'fa fa-chevron-up')}
                        />
                    ) : (
                        <Icon
                            className={classNames(classes.icon, 'fa fa-chevron-down')}
                        />
                    )}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem onClick={handleNavClick('/employees')} button dense>
                            <ListItemText className={classNames(classes.nested, classes.navText)} primary="Employees" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem onClick={() => handleNavClickExternal(PORTAL_FEEDBACK_URL)} button dense>
                    <ListItemIcon>
                        <Icon
                            className={classNames(classes.icon, 'fa fa-comment-dots')}
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.navText} primary="GIVE FEEDBACK" />
                </ListItem>
            </List>
        </div>
    );
};

EmployeeNav.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    handleNavClick: PropTypes.func.isRequired,
    handleNavClickExternal: PropTypes.func.isRequired,
};

export default connect()(withStyles(styles)(withRouter(EmployeeNav)));

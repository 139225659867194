import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

} from '@material-ui/core';

import StudentPlacementTableRow from './StudentPlacementTableRow';

function StudentPlacementTable(props) {
    const { placements } = props;
    return (
        <Paper>
            <h3>Placements</h3>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Company</TableCell>
                        <TableCell>Cohort</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Salary</TableCell>
                        <TableCell>Offer Date</TableCell>
                        {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {placements.map(placement => <StudentPlacementTableRow placement={placement} key={placement.id} />)}
                </TableBody>
            </Table>
        </Paper>
    );
}

StudentPlacementTable.propTypes = {
    placements: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({ placements: state.student.placements });

export default connect(mapStateToProps)(StudentPlacementTable);

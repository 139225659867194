import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Button,
    Hidden,
    Tooltip,
    Typography,
    Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { shortDateTime, extendedDateTime } from '../../../helpers/DateFormatHelper';
import { numberOfText } from '../../../helpers/CourseWorkTextHelper';

const styles = {
    tableRow: {
        cursor: 'pointer',
    },
    smallText: {
        fontSize: '11px',
        color: '#999',
    },
};

const AssignmentListRow = ({
    assignment,
    assignmentsToCopy,
    viewAssignmentDetails,
    classes,
    handleRowCheckbox,
    displayCheckboxes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={viewAssignmentDetails(assignment)}
    >
        {
            displayCheckboxes
            && (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={assignmentsToCopy.indexOf(assignment.id) >= 0}
                        onClick={event => handleRowCheckbox(event, assignment)}
                    />
                </TableCell>
            )
        }
        <TableCell>
            {assignment.name}
            <br />
            <Hidden smDown>
                {
                    assignment.tags
                    && assignment.tags.map((tag, i, arr) => {
                        // The last tag won't have a comma
                        let result = <span key={tag.id} className={classes.smallText}>{`${tag.name}`}</span>;
                        // All other tags are followed by a comma
                        if (i < arr.length - 1) {
                            result = <span key={tag.id} className={classes.smallText}>{`${tag.name}, `}</span>;
                        }
                        return result;
                    })
                }
            </Hidden>
            <Hidden mdUp>
                {assignment.cohort.name}
            </Hidden>
        </TableCell>
        <Hidden smDown>
            <TableCell>{assignment.cohort.name}</TableCell>
            <TableCell>{assignment.assignment_type === 'graded' ? 'Graded' : 'Not graded'}</TableCell>
            <TableCell>
                {
                    assignment.workflow_state === 'assigned' ? (
                        <Tooltip
                            title={(
                                <React.Fragment>
                                    <Typography color="inherit">
                                        {extendedDateTime(assignment.assigned_at)}
                                    </Typography>
                                </React.Fragment>
                            )}
                        >
                            <p>Assigned</p>
                        </Tooltip>

                    ) : (
                        <p>Unassigned</p>
                    )
                }
            </TableCell>
        </Hidden>
        <TableCell>
            {/* On hover, show the extended date / time */}
            <Tooltip
                title={(
                    <React.Fragment>
                        <Typography color="inherit">
                            {extendedDateTime(assignment.due_at)}
                        </Typography>
                    </React.Fragment>
                )}
            >
                <p>{shortDateTime(assignment.due_at)}</p>
            </Tooltip>
        </TableCell>
        <TableCell align="right">
            {
                assignment.due_at ? (
                    numberOfText(assignment.summary.completed, assignment.summary.total)
                ) : (
                    'n/a'
                )
            }
        </TableCell>
        <Hidden smDown>
            <TableCell align="right">
                {
                    assignment.assignment_type === 'graded' ? (
                        numberOfText(assignment.summary.graded, assignment.summary.completed)
                    ) : (
                        'n/a'
                    )
                }
            </TableCell>
            <TableCell align="right">
                <Button variant="outlined" onClick={viewAssignmentDetails(assignment)} type="button">View</Button>
            </TableCell>
        </Hidden>
    </TableRow>
);

AssignmentListRow.propTypes = {
    assignmentsToCopy: PropTypes.instanceOf(Array).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    viewAssignmentDetails: PropTypes.func.isRequired,
    handleRowCheckbox: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    displayCheckboxes: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    assignmentsToCopy: state.assignments.assignmentsToCopy,
});

export default connect(mapStateToProps)(withStyles(styles)(AssignmentListRow));

// Currently logged in employee
const userReducer = (state = { first_name: '', last_name: '' }, action) => {
    switch (action.type) {
    case 'SET_USER':
        return action.payload;
    case 'UNSET_USER':
        return { first_name: '', last_name: '' };
    default:
        return state;
    }
};

// user will be on the redux state at:
// state.user
export default userReducer;

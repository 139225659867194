import React from 'react';
import moment from 'moment';

export const shortStatusText = (cohort) => {
    const graduationDate = moment(cohort.graduation);
    const classroomStartDate = moment(cohort.classroom_start);
    const preworkStartDate = moment(cohort.prework_start);
    const applicationsCloseDate = moment(cohort.applications_close);
    const applicationsOpenDate = moment(cohort.applications_open);
    const now = moment();
    let result = <span className="status-text status-graduated">Pre-Applications</span>;
    if (graduationDate < now) {
        result = <span className="status-text status-graduated">Graduated</span>;
    } else if (classroomStartDate < now) {
        result = <span className="status-text status-classroom">Classroom</span>;
    } else if (preworkStartDate < now) {
        result = <span className="status-text status-classroom">Prework</span>;
    } else if (applicationsCloseDate < now) {
        result = <span className="status-text status-application-closed">Applications Closed</span>;
    } else if (applicationsOpenDate < now) {
        result = <span className="status-text status-graduated">Applications Open</span>;
    }
    return result;
};

export const longStatusText = (cohort) => {
    const graduationDate = moment(cohort.graduation);
    const classroomStartDate = moment(cohort.classroom_start);
    const preworkStartDate = moment(cohort.prework_start);
    const applicationsCloseDate = moment(cohort.applications_close);
    const applicationsOpenDate = moment(cohort.applications_open);
    const now = moment();
    let result = `Applications open in ${applicationsOpenDate.toNow()}`;
    if (graduationDate < now) {
        result = `Graduated ${graduationDate.fromNow()}`;
    } else if (classroomStartDate < now) {
        result = `Graduates in ${graduationDate.toNow(true)}`;
    } else if (preworkStartDate < now) {
        result = `Classroom starts in ${classroomStartDate.toNow(true)}`;
    } else if (applicationsCloseDate < now) {
        result = `Prework starts in ${preworkStartDate.toNow(true)}`;
    } else if (applicationsOpenDate < now) {
        result = `Applications close in ${applicationsCloseDate.toNow(true)}`;
    }
    return result;
};

export const cohortOptionSelect = (cohorts) => {
    const graduated = [];
    const inClassroom = [];
    const preClassroom = [];
    const today = moment(new Date());
    cohorts.map((cohort) => {
        const graduation = moment(cohort.graduation);
        const classroomStart = moment(cohort.classroom_start);
        const item = { value: cohort.id, label: cohort.cohort_name };
        if (graduation < today) {
            graduated.push(item);
        } else if (classroomStart < today) {
            inClassroom.push(item);
        } else {
            preClassroom.push(item);
        }
        return cohort;
    });
    const cohortOptions = [
        // { value: '', label: 'All Cohorts' },
        { options: inClassroom, label: 'In Classroom' },
        { options: preClassroom, label: 'Pre Classroom' },
        { options: graduated, label: 'Graduated' },
    ];
    return cohortOptions;
};

export default {
    shortStatusText,
    longStatusText,
    cohortOptionSelect,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Hidden,
    TablePagination,
    TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CohortAssignmentRow from './CohortAssignmentRow';
import PageTitle from '../../PageTitle/PageTitle';

const LIMIT = 20;

class CohortAssignmentsPage extends Component {
    constructor(props) {
        super(props);
        // TODO: This should eventually be moved into the cohort reducer
        // to maintain state after navigation.
        this.state = {
            sortOrder: {
                orderBy: 'due_at',
                order: 'desc',
            },
        };
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_USER' });
        // dispatch({ type: 'SET_STUDENT_MODAL', payload: { modalOpen: false } });
        dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload: this.getPayload() });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    backToCohortList = () => {
        const { history } = this.props;
        history.goBack();
    }

    viewAssignmentDetails = assignment => () => {
        const { history } = this.props;
        history.push(`/assignments/${assignment.id}`);
    }

    handleChangePage = (event, page) => {
        const {
            dispatch,
        } = this.props;
        const offset = page * LIMIT;
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_OFFSET', payload: { offset } });
        const payload = {
            ...this.getPayload(),
            offset,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
    }

    sortBy = orderBy => () => {
        const {
            sortOrder,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        let { order } = sortOrder;
        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const updatedSort = {
            orderBy,
            order,
        };
        this.setState({
            sortOrder: updatedSort,
        });
        const payload = {
            ...this.getPayload(),
            orderBy: updatedSort.orderBy,
            order: updatedSort.order,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
    };

    getPayload = () => {
        const {
            sortOrder,
        } = this.state;
        const {
            searchOffset,
            match,
        } = this.props;
        const payload = {
            order: sortOrder.order,
            orderBy: sortOrder.orderBy,
            cohortId: match.params.id,
            offset: searchOffset,
        };
        return payload;
    }

    render() {
        const {
            sortOrder,
        } = this.state;
        const {
            cohort,
            assignments,
            searchOffset,
        } = this.props;
        const page = searchOffset / LIMIT;
        return (
            <div className="container">
                <PageTitle
                    title={`${cohort.name} :: Assignments`}
                    navigation={
                        [
                            { name: 'details', path: `/cohorts/${cohort.id}` },
                            { name: 'assignments', path: `/cohorts/${cohort.id}/assignments`, selected: true },
                            { name: 'feedback', path: `/cohorts/${cohort.id}/feedback` },
                        ]
                    }
                    // Action buttons are created by the PageTitle component
                    actions={[
                        {
                            label: 'Add New Assignment',
                            // Dispatch an action...
                            type: 'UNSET_EDITING_ASSIGNMENT',
                            // ...and navigate to this page.
                            path: '/assignments/new',
                            // history.state to prepopulate the selected cohort
                            state: {
                                value: cohort.id,
                                label: cohort.name,
                            },
                        },
                    ]}
                />
                <Paper>
                    {/* TODO: Add pagination and sorting to this page */}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'name' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden smDown>
                                    <TableCell
                                        sortDirection={sortOrder.orderBy === 'assignment_type' && sortOrder.order}
                                    >
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'assignment_type'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('assignment_type')}
                                        >
                                            Type
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Status</TableCell>
                                </Hidden>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'due_at'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('due_at')}
                                    >
                                        Due At
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right"># Completed</TableCell>
                                {/* Only three rows can fit on most mobile devices */}
                                <Hidden smDown>
                                    <TableCell align="right"># Graded</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        {
                            assignments
                            && assignments.rows
                            && assignments.rows.length > 0
                            && assignments.rows.map(assignment => (
                                <CohortAssignmentRow
                                    divider
                                    key={assignment.id}
                                    assignment={assignment}
                                    viewAssignmentDetails={this.viewAssignmentDetails}
                                />
                            ))
                        }
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[LIMIT]}
                        component="div"
                        count={assignments.count}
                        rowsPerPage={LIMIT}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

CohortAssignmentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    assignments: PropTypes.instanceOf(Object).isRequired,
    searchOffset: PropTypes.number.isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    assignments: state.cohort.assignments,
    searchOffset: state.cohort.assignmentSearchOffset,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(CohortAssignmentsPage);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedback: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    feedbackScore: {
        fontSize: '18px',
        textAlign: 'center',
        minWidth: '120px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
};

function formatFeedbackNumber(entry) {
    let result = '';
    if (entry) {
        result = `${Number(entry).toFixed(0)}`;
    }
    return result;
}

const StudentReflectionModal = (props) => {
    const {
        dispatch,
        closeActionType,
        classes,
        open,
        fullScreen,
        feedback,
        previous,
        next,
    } = props;
    return (
        <>
            {
                feedback
                && (
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {feedback.feedback_on ? `Student Feedback :: ${feedback.feedback_on}` : 'Student Feedback'}
                        </DialogTitle>
                        <DialogContent align="left" className={classes.feedbackContainer}>
                            <div className={classes.scoreContainer}>
                                <div>
                                    <p className={classes.feedbackScore}>
                                        {formatFeedbackNumber(feedback.understanding_score)}
                                    </p>
                                    <p className="text-center">
                                        Understanding
                                    </p>
                                </div>
                                <div>
                                    <p className={classes.feedbackScore}>
                                        {formatFeedbackNumber(feedback.support_score)}
                                    </p>
                                    <p className="text-center">
                                        Support
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div>
                                {
                                    feedback.feeling_explanation
                                    && feedback.feeling_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>One Word</strong>
                                            <div>
                                                {feedback.feeling_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.understanding_explanation
                                    && feedback.understanding_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Understanding Comment</strong>
                                            <div>
                                                {feedback.understanding_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.support_explanation
                                    && feedback.support_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Support Comment</strong>
                                            <div>
                                                {feedback.support_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.thanks
                                    && feedback.thanks.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Thanks</strong>
                                            <div>
                                                {feedback.thanks}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.accomplishment
                                    && feedback.accomplishment.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Accomplishment</strong>
                                            <div>
                                                {feedback.accomplishment}
                                            </div>
                                        </div>
                                    )
                                }
                                {/* {
                                    feedback.recommended_experiences
                                    && feedback.recommended_experiences.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Suggest</strong>
                                            <div>
                                                {feedback.recommended_experiences}
                                            </div>
                                        </div>
                                    )
                                } */}
                            </div>
                        </DialogContent>
                        <br />
                        <DialogActions>
                            {
                                previous
                                && <Button onClick={previous}>Previous</Button>
                            }
                            {
                                next
                                && <Button onClick={next}>Next</Button>
                            }
                            <Button
                                onClick={() => dispatch(
                                    {
                                        type: closeActionType,
                                        payload: { modalOpen: false },
                                    },
                                )}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

StudentReflectionModal.defaultProps = {
    previous: undefined,
    next: undefined,
};

StudentReflectionModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    feedback: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    closeActionType: PropTypes.string.isRequired,
    previous: PropTypes.func,
    next: PropTypes.func,
};

export default connect()(withMobileDialog()(withStyles(styles)(StudentReflectionModal)));

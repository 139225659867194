import React from 'react';
import { Button, ListItem, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PhotoHelper from '../../../helpers/PhotoHelper';

const { getPhotoUrl } = PhotoHelper;

const styles = {
    studentTitleText: {
        width: '100%',
        padding: '0px 15px',
    },
    feedbackScore: {
        fontSize: '18px',
        textAlign: 'center',
        minWidth: '80px',
    },
    placementText: {
        minWidth: '120px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
};

const CohortStudentRow = ({
    user,
    student,
    getPlacementText,
    viewStudentDetails,
    classes,
}) => (
    <ListItem
        divider
        button
        onClick={viewStudentDetails(student)}
    >
        <div>
            <img
                className="placeholder-photo-sm"
                src={getPhotoUrl(user.s3_bucket, student.id, student.photo_file_name, 'thumb')}
                alt={student.first_name}
            />
        </div>
        <div className={classes.studentTitleText}>
            <h5>{`${student.first_name} ${student.last_name}`}</h5>
            <p>{student.email}</p>
        </div>
        <div className={classes.placementText}>
            {getPlacementText(student)}
        </div>
        <Hidden xsDown>
            <div>
                <p className={classes.feedbackScore}>{Number(student.avg_feeling).toFixed(1)}</p>
                <p className="text-center">Feeling</p>
            </div>
            <div>
                <p className={classes.feedbackScore}>
                    {Number(student.avg_understanding).toFixed(1)}
                </p>
                <p className="text-center">Understanding</p>
            </div>
            <div>
                <p className={classes.feedbackScore}>{Number(student.avg_support).toFixed(1)}</p>
                <p className="text-center">Support</p>
            </div>
            <div>
                <p className={classes.feedbackScore}>{Number(student.avg_score).toFixed(1)}</p>
                <p className="text-center">Assignments</p>
            </div>
            <div className={classes.actionButtons}>
                <Button variant="outlined" onClick={viewStudentDetails(student)} type="button">View</Button>
            </div>
        </Hidden>
    </ListItem>
);

CohortStudentRow.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    getPlacementText: PropTypes.func.isRequired,
    viewStudentDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortStudentRow);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Hidden,
    Drawer,
    SwipeableDrawer,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import EmployeeNav from './EmployeeNav';
import StudentNav from './StudentNav';

const drawerWidth = 200;

const styles = theme => ({
    sideNav: {
        padding: '0',
        height: '100%', /* 100% Full-height */
        backgroundColor: '#222',
        overflowX: 'hidden', /* Disable horizontal scroll */
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    betaText: {
        // backgroundColor: theme.palette.primary.main,
        padding: '0px 22px',
        width: '125px',
        textAlign: 'center',
    },
    logo: {
        width: '125px',
        height: 'auto',
        padding: '30px 23px 10px 23px',
    },
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
        height: 'auto',
        width: 'auto',
    },
    navText: {
        margin: 0,
        padding: 0,
        color: 'white',
    },
});

class SideNav extends Component {
    handleDrawerToggle = () => {
        const { dispatch, drawerOpen } = this.props;
        dispatch({ type: 'SET_DRAWER_STATE', payload: { drawerOpen: !drawerOpen } });
    };

    handleNavClick = path => () => {
        const { dispatch, history } = this.props;
        history.push(path);
        dispatch({ type: 'SET_DRAWER_STATE', payload: { drawerOpen: false } });
    };

    handleNavClickExternal = (URL) => {
        window.open(URL, '_blank');
    }

    render() {
        const {
            classes,
            theme,
            user,
            drawerOpen,
        } = this.props;

        return (
            <div>
                {
                    user
                    && user.first_name
                    && (
                        <nav className={classes.drawer}>
                            <Hidden mdUp implementation="css">
                                <SwipeableDrawer
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={drawerOpen}
                                    onOpen={this.handleDrawerToggle}
                                    onClose={this.handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {
                                        user.role === 2
                                        && (
                                            <EmployeeNav
                                                handleNavClick={this.handleNavClick}
                                                handleNavClickExternal={this.handleNavClickExternal}
                                            />
                                        )
                                    }
                                    {
                                        user.role === 1
                                        && (
                                            <StudentNav
                                                handleNavClick={this.handleNavClick}
                                            />
                                        )
                                    }
                                </SwipeableDrawer>
                            </Hidden>
                            <Hidden smDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    variant="permanent"
                                    open
                                >
                                    {
                                        user.role === 2
                                        && (
                                            <EmployeeNav
                                                handleNavClick={this.handleNavClick}
                                                handleNavClickExternal={this.handleNavClickExternal}
                                            />
                                        )
                                    }
                                    {
                                        user.role === 1
                                        && (
                                            <StudentNav
                                                handleNavClick={this.handleNavClick}
                                            />
                                        )
                                    }
                                </Drawer>
                            </Hidden>
                        </nav>
                    )
                }
            </div>
        );
    }
}

SideNav.propTypes = {
    theme: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        role: PropTypes.number,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    drawerOpen: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    drawerOpen: state.drawer.drawerOpen,
});

const appWithStyles = withStyles(styles, { withTheme: true })(withRouter(SideNav));

export default connect(mapStateToProps)(appWithStyles);

import React from 'react';
import moment from 'moment';

// Students won't see early or on time text
export const studentStatusText = (courseWork, assignment) => {
    const completedAt = moment(courseWork.completed_at);
    // Give them a 5 minute buffer
    let dueAt = moment(assignment.due_at).add({ minutes: 5 });
    const assignedAt = moment(assignment.assigned_at);
    const duration = moment.duration(dueAt.diff(assignedAt));

    if (Math.abs(duration.asHours()) < 24) {
        // Daily assignments have a larger late buffer
        dueAt = moment(assignment.due_at).add({ minutes: 60 });
    }
    let result = <span className="status-text status-uncompleted">Uncompleted</span>;
    if (!courseWork.completed_at && moment() > dueAt) {
        result = <span className="status-text status-missing">Missing</span>;
    } else if (!courseWork.completed_at) {
        result = <span className="status-text status-uncompleted">Uncompleted</span>;
    } else if (assignment.assignment_type === 'graded' && courseWork.instructor_comment) {
        result = <span className="status-text status-graded">Graded</span>;
    } else if (!assignment.due_at || completedAt < dueAt) {
        result = <span className="status-text status-early">Complete</span>;
    } else {
        result = <span className="status-text status-late">Late</span>;
    }
    return result;
};

export const shortStatusText = (courseWork, assignment) => {
    const completedAt = moment(courseWork.completed_at);
    // Give them a 5 minute buffer
    let dueAt = moment(assignment.due_at).add({ minutes: 5 });
    const assignedAt = moment(assignment.assigned_at);
    const duration = moment.duration(dueAt.diff(assignedAt));
    // Daily assignments are early if turned in 15 minutes in advance
    let earlyAt = moment(assignment.due_at).subtract({ minutes: 15 });
    if (Math.abs(duration.asHours()) > 24) {
        // Weekend assignments have a larger early threshold
        earlyAt = moment(assignment.due_at).subtract({ hours: 6 });
    } else {
        // Daily assignments have a larger late buffer
        dueAt = moment(assignment.due_at).add({ minutes: 60 });
    }
    let result = <span className="status-text status-uncompleted">Uncompleted</span>;
    if (!courseWork.completed_at && moment() > dueAt) {
        result = <span className="status-text status-missing">Missing</span>;
    } else if (!courseWork.completed_at) {
        result = <span className="status-text status-uncompleted">Uncompleted</span>;
    } else if (!assignment.due_at) {
        result = <span className="status-text status-early">Complete</span>;
    } else if (completedAt < earlyAt) {
        result = <span className="status-text status-early">Early</span>;
    } else if (completedAt < dueAt) {
        result = <span className="status-text status-on-time">On Time</span>;
    } else {
        result = <span className="status-text status-late">Late</span>;
    }
    return result;
};

// Sets green background if 15/15 (100%), otherwise yellow
export const numberOfText = (first, second) => {
    let result = <span className="status-text status-complete">{`${first} / ${second}`}</span>;
    if (first < second) {
        result = <span className="status-text status-incomplete">{`${first} / ${second}`}</span>;
    }
    return result;
};

export default {
    shortStatusText,
    numberOfText,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
        minWidth: '250px',
        marginRight: '10px',
        marginTop: '20px',
    },
    helperText: {
        marginTop: '6px',
        marginLeft: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    formContainer: {
        overflow: 'hidden',
        marginBottom: '10px',
    },
};

const SubmitCourseWorkModal = (props) => {
    const {
        dispatch,
        classes,
        open,
        fullScreen,
        onClose,
        onSubmit,
        courseWork,
    } = props;

    const handleChangeFor = prop => (event) => {
        const copy = {
            ...courseWork,
            [prop]: event.target.value,
        };
        // Modify the selected course work directly, we may eventually want to make
        // this a separate reducer.
        dispatch({
            type: 'SET_STUDENT_PORTAL_COURSE_WORK',
            payload: copy,
        });
    };

    return (
        <Dialog fullScreen={fullScreen} open={open}>
            <DialogTitle id="responsive-dialog-title">
                {courseWork && courseWork.assignment.name}
            </DialogTitle>
            {courseWork
            && (
                <DialogContent align="left" className={classes.feedbackContainer}>
                    <form className={classes.formContainer} noValidate>
                        <TextField
                            id="repository"
                            label="Submission:"
                            type="text"
                            value={courseWork.repository}
                            onChange={handleChangeFor('repository')}
                            className={classes.textField}
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="site"
                            label="Website:"
                            type="text"
                            value={courseWork.site}
                            onChange={handleChangeFor('site')}
                            className={classes.textField}
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="student_comment"
                            label="Comment:"
                            type="text"
                            value={courseWork.student_comment}
                            onChange={handleChangeFor('student_comment')}
                            className={classes.textField}
                            multiline
                            rows="5"
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                </DialogContent>
            )}

            <br />
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SubmitCourseWorkModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
};

const assignModal = withMobileDialog()(withStyles(styles)(SubmitCourseWorkModal));
export default connect()(assignModal);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
    List,
    ListItem,
    Icon,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';

const drawerWidth = 200;

const styles = theme => ({
    sideNav: {
        padding: '0',
        height: '100%', /* 100% Full-height */
        backgroundColor: '#222',
        overflowX: 'hidden', /* Disable horizontal scroll */
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    betaText: {
        // backgroundColor: theme.palette.primary.main,
        padding: '0px 22px',
        width: '125px',
        textAlign: 'center',
    },
    logo: {
        width: '185px',
        height: 'auto',
        padding: '30px 23px 10px 23px',
    },
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
        height: 'auto',
        width: 'auto',
    },
    navText: {
        margin: 0,
        padding: 0,
        color: 'white',
    },
});

const classroomHasStarted = (cohort) => {
    const classroomStart = moment(cohort.classroom_start);
    return moment() > classroomStart;
};

const StudentNav = ({
    classes,
    handleNavClick,
    cohort,
}) => (
    <div className={classes.sideNav}>
        <Logo className={classes.logo} />
        <List>
            {
                classroomHasStarted(cohort)
                && (
                    <ListItem onClick={handleNavClick('/student/reflections')} button dense>
                        <ListItemIcon>
                            <Icon
                                className={classNames(classes.icon, 'far fa-comment-dots')}
                            />
                        </ListItemIcon>
                        <ListItemText className={classes.navText} primary="Reflections" />
                    </ListItem>
                )
            }
            <ListItem onClick={handleNavClick('/student/assignments')} button dense>
                <ListItemIcon>
                    <Icon
                        className={classNames(classes.icon, 'fa fa-desktop')}
                    />
                </ListItemIcon>
                <ListItemText className={classes.navText} primary="Assignments" />
            </ListItem>
            <ListItem onClick={handleNavClick('/profile')} button dense>
                <ListItemIcon>
                    <Icon
                        className={classNames(classes.icon, 'fa fa-user')}
                    />
                </ListItemIcon>
                <ListItemText className={classes.navText} primary="Profile" />
            </ListItem>
        </List>
    </div>
);

StudentNav.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    handleNavClick: PropTypes.func.isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    cohort: state.studentPortal.cohort,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(StudentNav)));

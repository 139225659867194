import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CodeBlock from '../../General/CodeBlock';
import PageTitle from '../../PageTitle/PageTitle';
import SubmitCourseWorkModal from '../../Modal/SubmitCourseWorkModal';
import { extendedDateTime } from '../../../helpers/DateFormatHelper';
import { studentStatusText } from '../../../helpers/CourseWorkTextHelper';

const styles = {
    textField: {
        fontSize: '12px',
    },
};

class StudentAssignmentDetail extends Component {
    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT_PORTAL_COURSE_WORK' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
    }

    handleRefresh = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
    }

    getGradeForCourseWork = (courseWork) => {
        let result = 'Not Graded';
        if (courseWork.workflow_state === 'graded') {
            result = courseWork.grade;
        }
        return result;
    }

    onClose = () => {
        const { dispatch, match } = this.props;
        dispatch({
            type: 'SET_STUDENT_COURSE_WORK_MODAL',
            payload: {
                modalOpen: false,
            },
        });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
    }

    onSubmit = () => {
        const { dispatch, selectedCourseWork } = this.props;
        dispatch({
            type: 'PUT_STUDENT_PORTAL_COURSE_WORK',
            payload: selectedCourseWork,
        });
    }

    render() {
        const {
            assignmentModalOpen,
            selectedCourseWork,
        } = this.props;

        const actionButtons = [];
        let buttonLabel = 'Submit Your Work';
        if (selectedCourseWork && selectedCourseWork.completed_at) {
            buttonLabel = 'Update Your Submission';
        }

        if (selectedCourseWork && !selectedCourseWork.grade) {
            actionButtons.push(
                {
                    label: buttonLabel,
                    type: 'SET_STUDENT_COURSE_WORK_MODAL',
                    payload: { modalOpen: true },
                    color: 'primary',
                    variant: 'contained',
                },
            );
        }

        return (
            <>
                {
                    selectedCourseWork
                    && selectedCourseWork.assignment
                    && (
                        <div className="container">
                            <PageTitle
                                title={`Assignment :: ${selectedCourseWork.assignment.name}`}
                                // Action buttons are created by the PageTitle component
                                actions={actionButtons}
                            />
                            <Paper>
                                <p>
                                    {
                                        studentStatusText(
                                            selectedCourseWork,
                                            selectedCourseWork.assignment,
                                        )
                                    }
                                </p>
                                <br />
                                <p><strong>Due Date:</strong></p>
                                <p>{`${extendedDateTime(selectedCourseWork.assignment.due_at)}`}</p>
                                <br />
                                {
                                    selectedCourseWork.assignment.repository
                                    && (
                                        <>
                                            <p><strong>Website:</strong></p>
                                            <p>
                                                <a href={selectedCourseWork.assignment.repository}>
                                                    {selectedCourseWork.assignment.repository}
                                                </a>
                                            </p>
                                            <br />
                                        </>
                                    )
                                }
                                <p><strong>Assignment Details:</strong></p>
                                <ReactMarkdown
                                    align="left"
                                    source={selectedCourseWork.assignment.notes}
                                    renderers={{ code: CodeBlock }}
                                />
                            </Paper>
                            {
                                selectedCourseWork.instructor_comment
                                && (
                                    <Paper>
                                        <h5>Instructor Feedback</h5>
                                        <br />
                                        {
                                            selectedCourseWork.employee
                                            && selectedCourseWork.employee.first_name
                                            && (
                                                <p>{`Graded By: ${selectedCourseWork.employee.first_name} ${selectedCourseWork.employee.last_name}`}</p>
                                            )
                                        }
                                        <hr />
                                        <ReactMarkdown
                                            align="left"
                                            source={selectedCourseWork.instructor_comment}
                                            renderers={{ code: CodeBlock }}
                                        />
                                    </Paper>
                                )
                            }
                            {
                                selectedCourseWork.student_comment
                                && selectedCourseWork.student_comment !== ''
                                && (
                                    <Paper>
                                        <h5>Student Comment</h5>
                                        <hr />
                                        <ReactMarkdown
                                            align="left"
                                            source={selectedCourseWork.student_comment}
                                            renderers={{ code: CodeBlock }}
                                        />
                                    </Paper>
                                )
                            }
                            {
                                selectedCourseWork
                                && (
                                    <SubmitCourseWorkModal
                                        courseWork={selectedCourseWork}
                                        open={assignmentModalOpen}
                                        onClose={this.onClose}
                                        onSubmit={this.onSubmit}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </>
        );
    }
}

StudentAssignmentDetail.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    assignmentModalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    selectedCourseWork: state.studentPortal.selectedCourseWork,
    assignmentModalOpen: state.studentPortal.assignmentModalOpen,
});

export default withStyles(styles)(connect(mapStateToProps)(StudentAssignmentDetail));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
};

const ErrorMessageModal = (props) => {
    const {
        classes,
        open,
        fullScreen,
        error,
        dispatch,
        closeActionType,
    } = props;
    return (
        <Dialog fullScreen={fullScreen} open={open}>
            <DialogTitle id="responsive-dialog-title">
                {`Error (${error.status})`}
            </DialogTitle>
            <DialogContent align="left" className={classes.feedbackContainer}>
                There was an error trying to process your request.
                <br />
                <br />
                <pre>{error.data}</pre>
            </DialogContent>
            <br />
            <DialogActions>
                <Button
                    onClick={() => dispatch({
                        type: closeActionType,
                        payload: {
                            modalOpen: false,
                        },
                    })}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ErrorMessageModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    closeActionType: PropTypes.string.isRequired,
    error: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    open: state.error.modalOpen,
    error: state.error.currentError,
});
const errorModal = withMobileDialog()(withStyles(styles)(ErrorMessageModal));
export default connect(mapStateToProps)(errorModal);

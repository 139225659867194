import {
    createMuiTheme,
} from '@material-ui/core/styles';

/**
 * Light theme with dark sidebar example:
 * https://codesandbox.io/s/61z38zjr7w
 */
const lightTheme = createMuiTheme({
    overrides: {
        MuiButton: { // Name of the component / style sheet
            root: { // All variants will get these styles
                textTransform: 'none',
            },
            text: { // Variant specific styling
                color: '#337277',
                size: '13px',
                '&:hover': {
                    color: '#1c3f41',
                },
            },
            contained: { // Variant specific styling
                color: 'white',
                fontSize: '12px',
                backgroundColor: '#07AA9E',
                '&:hover': {
                    backgroundColor: '#069B90',
                },
                '&:active': {
                    backgroundColor: '#19383A',
                    borderColor: '#050B0C',
                },
                padding: '5px 10px',
            },
            outlined: { // Name of the rule
                color: '#676a6c',
                fontSize: '12px',
                '&:hover': {
                    color: '#333333',
                },
                padding: '5px 10px',
            },
        },
        MuiListItem: {
            root: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
            },
        },
        MuiPaper: {
            rounded: {
                margin: '15px',
                padding: '15px',
            },
        },
        MuiTableRow: {
            root: {
                '&:nth-child(even)': {
                    background: '#FAFAFA',
                },
                '&:nth-child(odd)': {
                    background: '#FFF',
                },
            },
        },
        MuiTableCell: {
            root: {
                minWidth: '75px',
                padding: '5px 5px',
            },
            body: {
                color: '#676a6c',
            },
        },
        MuiTypography: {
            root: {
                color: '#676a6c',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '\'Open Sans\', sans- serif',
    },
    palette: {
        primary: {
            // light: '#63ccff',
            main: '#07AA9E',
            dark: '#222222',
            contrastText: '#fff',
        },
        secondary: {
            main: '#AB3E07',
            contrastText: '#fff',
        },
    },
    // Set default border radius for all shapes
    shape: {
        borderRadius: 3,
    },
});

const darkTheme = createMuiTheme({
    overrides: {
        MuiListItem: {
            root: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                '&:focus': {
                    backgroundColor: '#333',
                },
            },
            dense: {
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '\'Open Sans\', sans- serif',
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#07AA9E',
            dark: '#222222',
            contrastText: '#fff',
        },
    },
    // Set default border radius for all shapes
    shape: {
        borderRadius: 3,
    },
});

const theme = {
    lightTheme,
    darkTheme,
};

export default theme;

import axios from 'axios';
import {
    put, all, takeLatest,
} from 'redux-saga/effects';

function* fetchStudentPortalFeedback() {
    try {
        const response = yield axios.get('api/students/feedback');
        yield put({ type: 'SET_STUDENT_PORTAL_FEEDBACK_HISTORY', payload: response.data });
        yield put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK_LATEST' });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

// Get the most recent feedback for the user. Used to determine whether to show
// the feedback (reflection) form. We may eventually add pagination to the feedback
// history so we can't count on that having the last feedback entry.
function* fetchStudentPortalLastFeedback() {
    try {
        const response = yield axios.get('api/students/feedback/latest');
        yield put({ type: 'SET_STUDENT_PORTAL_FEEDBACK_LATEST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalAssignments() {
    try {
        const response = yield axios.get('api/students/assignments');
        yield put({ type: 'SET_STUDENT_PORTAL_ASSIGNMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalCohort() {
    try {
        const response = yield axios.get('api/students/cohort');
        yield put({ type: 'SET_STUDENT_PORTAL_COHORT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalCourseWorkDetail(action) {
    try {
        const response = yield axios.get(`api/students/coursework/${action.payload.id}`);
        yield put({ type: 'SET_STUDENT_PORTAL_COURSE_WORK', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* putStudentPortalCourseWork(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/students/coursework', action.payload);
        yield put({ type: 'FETCH_STUDENT_PORTAL_ASSIGNMENTS' });
        yield put({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: action.payload.assignmentId } });
        yield put({
            type: 'SET_STUDENT_COURSE_WORK_MODAL',
            payload: {
                modalOpen: false,
            },
        });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* putStudentPortalFeedback(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/students/feedback', action.payload);
        yield put({ type: 'UNSET_STUDENT_DAILY_FEEDBACK' });
        yield put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK' });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchStudentPortalInfo() {
    yield all([
        put({ type: 'FETCH_STUDENT_PORTAL_ASSIGNMENTS' }),
        put({ type: 'FETCH_STUDENT_PORTAL_COHORT' }),
        put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK' }),
    ]);
}

function* userSaga() {
    yield takeLatest('FETCH_ALL_STUDENT_PORTAL_INFO', fetchStudentPortalInfo);
    yield takeLatest('FETCH_STUDENT_PORTAL_FEEDBACK', fetchStudentPortalFeedback);
    yield takeLatest('FETCH_STUDENT_PORTAL_ASSIGNMENTS', fetchStudentPortalAssignments);
    yield takeLatest('FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', fetchStudentPortalCourseWorkDetail);
    yield takeLatest('PUT_STUDENT_PORTAL_COURSE_WORK', putStudentPortalCourseWork);
    yield takeLatest('PUT_STUDENT_PORTAL_FEEDBACK', putStudentPortalFeedback);
    yield takeLatest('FETCH_STUDENT_PORTAL_COHORT', fetchStudentPortalCohort);
    yield takeLatest('FETCH_STUDENT_PORTAL_FEEDBACK_LATEST', fetchStudentPortalLastFeedback);
}

export default userSaga;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PhotoHelper from '../../../../helpers/PhotoHelper';

const { getPhotoUrl } = PhotoHelper;

const styles = {
    container: {
        display: 'flex',
        width: '100%',
    },
    details: {
        flex: 1,
        minHeight: '200px',
    },
};

/**
 * Stats returned from the server can be null. This function
 * checks for a valid numner and formats the entry accordingly.
 */
function formatStatNumber(entry) {
    let result = '0.00';
    // We have an entry and it's a valid number
    if (entry && !Number.isNaN(Number(entry))) {
        result = `${Number(entry).toFixed(2)}`;
    }
    return result;
}

class StudentProfile extends Component {
    getPlacementText = (student) => {
        let result = '';
        if (student.placement_count > 0 || student.application_status === 'placed') {
            result = <span className="status-text status-placed">Placed</span>;
        } else if (student.skip_placement_reporting) {
            result = <span className="status-text status-excluded">Excluded</span>;
        } else if (student.application_status === 'dropped_out') {
            result = <span className="status-text status-unplaced">Dropped Out</span>;
        } else {
            result = <span className="status-text status-unplaced">Unplaced</span>;
        }
        return result;
    }

    render() {
        const {
            user,
            student,
            cohort,
            classes,
        } = this.props;
        return (
            <div className={classes.container}>
                <img
                    className="placeholder-photo-lg"
                    src={getPhotoUrl(user.s3_bucket, student.id, student.photo_file_name)}
                    alt={student.first_name}
                />
                <Paper className={classes.details}>
                    <Grid container>
                        <Grid item xs={12}>
                            <h5>
                                {`${student.first_name} ${student.last_name}`}
                            </h5>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <p>{this.getPlacementText(student)}</p>
                            <br />
                            <p>{student.email}</p>
                            <p>{student.phone}</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_feeling)}
                                            <br />
                                            <p>Feeling</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_feeling)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_understanding)}
                                            <br />
                                            <p>Understanding</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_understanding)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_support)}
                                            <br />
                                            <p>Support</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_support)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

StudentProfile.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StudentProfile);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    // Button,
} from '@material-ui/core';
import PageTitle from '../../PageTitle/PageTitle';

class EmployeeListPage extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_EMPLOYEE_LIST' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    // editEmployee = (selectedEmployee) => {
    //     const { history } = this.props;
    //     history.push(`/employees/${selectedEmployee.id}/edit`);
    // }

    render() {
        const { employees } = this.props;

        return (
            <div className="container">
                <PageTitle
                    title="Employees"
                    actions={[{
                        label: 'Add New Employee',
                        // Dispatch an action...
                        type: 'UNSET_EDITING_EMPLOYEE',
                        // ...and navigate to this page.
                        path: '/employees/new',
                    }]}
                />
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                employees.map(employee => (
                                    <TableRow key={employee.id}>
                                        <TableCell>{`${employee.first_name} ${employee.last_name}`}</TableCell>
                                        <TableCell>{employee.email}</TableCell>
                                        <TableCell>{employee.status}</TableCell>
                                        <TableCell align="right">
                                            {/* <Button variant="outlined">Edit</Button> */}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

EmployeeListPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    employees: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    employees: state.employee.employeeList,
});

export default connect(mapStateToProps)(EmployeeListPage);

function getPhotoUrl(bucket, studentId, photoName, size = 'medium') {
    let url = `student_${size}_missing.svg`;

    if (photoName && process.env.NODE_ENV === 'production') {
        // Paperclip, the ruby plugin previously used for S3 uploads, stores
        // photos using a folder structure with the user id. For example,
        // an id of 3012 would turn into 000/003/012
        let idText = `${studentId}`;
        while (idText.length < 9) {
            // Prepend 0's until we get to 9 digits
            idText = `0${idText}`;
        }
        // Split the string into a folder path
        const folders = `${idText.substring(0, 3)}/${idText.substring(3, 6)}/${idText.substring(6, idText.length)}`;
        url = `https://${bucket}.s3.amazonaws.com/students/photos/${folders}/${size}/${photoName}`;
    }
    return url;
}

export default {
    getPhotoUrl,
};

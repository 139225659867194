import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchAssignmentList(action) {
    try {
        const response = yield axios.get('api/assignments/list', { params: action.payload });
        yield put({ type: 'SET_ASSIGNMENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment list - get request failed', error);
    }
}

// Fetch list of assignment ids for a given cohort. This is used to copy assignments to
// another cohort.
function* fetchAssignmentsToCopy(action) {
    try {
        const response = yield axios.get('api/assignments/list/all', { params: action.payload });
        yield put({ type: 'SELECT_ALL_ASSIGNMENT_CHECKBOX', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignments to copy - get request failed', error);
    }
}

function* copyManyAssignments(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('api/assignments/copy', action.payload);
        yield delay(1000);
        yield put({ type: 'SET_SENDING_ASSIGNMENT_COMPLETE', payload: { modalOpen: true } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Copy many assignments - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}


function* fetchAssignmentDetails(action) {
    try {
        const response = yield axios.get(`api/assignments/detail/${action.payload.id}`);
        yield put({ type: 'SET_ASSIGNMENT', payload: response.data });
        const payload = {
            assignmentId: action.payload.id,
            cohortId: response.data.cohort_id,
        };
        yield put({ type: 'FETCH_COURSE_WORK', payload });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment details - get request failed', error);
    }
}

function* fetchCourseWork(action) {
    try {
        const response = yield axios.get(`api/assignments/coursework/${action.payload.assignmentId}/${action.payload.cohortId}`);
        yield put({ type: 'SET_COURSE_WORK_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - get request failed', error);
    }
}

function* gradeCourseWork(action) {
    try {
        // When grading, pass the course work id
        yield axios.put(`api/assignments/grade/${action.payload.id}`, action.payload);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - put request failed', error);
    }
}

function* updateAssignment(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/assignments/update', action.payload);
        yield delay(250);
        yield action.history.replace(`/assignments/${action.payload.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment create - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* createAssignment(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const response = yield axios.post('api/assignments', action.payload);
        // Store the newly created assignment so we can display it on the page
        yield put({ type: 'SET_ASSIGNMENT', payload: response.data });
        yield delay(300);
        yield action.history.replace(`/assignments/${response.data.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment create - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* assignAssignment(action) {
    try {
        yield axios.put(`api/assignments/assign/${action.payload.id}`, { dueAt: action.payload.dueAt });
        // Refresh the assignment
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: action.payload.id } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment assign - post request failed', error);
    }
}

function* unassignAssignment(action) {
    try {
        yield axios.put(`api/assignments/unassign/${action.payload.id}`);
        // Refresh the assignment
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: action.payload.id } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment unassign - put request failed', error);
    }
}

function* fetchTagList() {
    try {
        const response = yield axios.get('api/assignments/tags');
        yield put({ type: 'SET_TAG_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment tags - get request failed', error);
    }
}

function* userSaga() {
    yield takeLatest('FETCH_ASSIGNMENT_LIST', fetchAssignmentList);
    yield takeLatest('FETCH_ASSIGNMENT_DETAILS', fetchAssignmentDetails);
    yield takeLatest('FETCH_COURSE_WORK', fetchCourseWork);
    yield takeLatest('GRADE_COURSE_WORK', gradeCourseWork);
    yield takeLatest('POST_ASSIGNMENT', createAssignment);
    yield takeLatest('PUT_ASSIGNMENT', updateAssignment);
    yield takeLatest('ASSIGN_ASSIGNMENT', assignAssignment);
    yield takeLatest('UNASSIGN_ASSIGNMENT', unassignAssignment);
    yield takeLatest('FETCH_TAG_LIST', fetchTagList);
    yield takeLatest('SELECT_ALL_ASSIGNMENTS_FOR_COHORT', fetchAssignmentsToCopy);
    yield takeLatest('COPY_MANY_ASSIGNMENTS', copyManyAssignments);
}

export default userSaga;

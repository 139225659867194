import { all } from 'redux-saga/effects';
import loginSaga from './loginSaga';
import userSaga from './userSaga';
import cohortSaga from './cohortSaga';
import studentSaga from './studentSaga';
import assignmentSaga from './assignmentSaga';
import campusSaga from './campusSaga';
import employeeSaga from './employeeSaga';
import studentPortalSaga from './studentPortalSaga';

// rootSaga is the primary saga.
// It bundles up all of the other sagas so our project can use them.
// This is imported in index.js as rootSaga

// some sagas trigger other sagas, as an example
// the registration triggers a login
// and login triggers setting the user
export default function* rootSaga() {
    yield all([
        loginSaga(),
        userSaga(),
        cohortSaga(),
        studentSaga(),
        assignmentSaga(),
        campusSaga(),
        employeeSaga(),
        studentPortalSaga(),
    ]);
}

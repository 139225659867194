import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import {
    Icon,
} from '@material-ui/core';


const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '8px',
        height: 'auto',
        width: 'auto',
    },
});

function ExpandArrowIcon(props) {
    const { expanded, classes } = props;
    return (
        <>
            {expanded ? (
                <Icon
                    className={classNames(classes.icon, 'fa fa-chevron-down')}
                />
            ) : (
                <Icon
                    className={classNames(classes.icon, 'fa fa-chevron-right')}
                />
            )
            }
        </>
    );
}

ExpandArrowIcon.propTypes = {
    expanded: PropTypes.bool.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ExpandArrowIcon);

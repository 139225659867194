import React from 'react';
import {
    Card,
    CardContent,
    Divider,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CohortTextHelper from '../../../helpers/CohortTextHelper';

const styles = {
    card: {
        margin: '15px',
    },
    cardContent: {
        marginTop: '10px',
    },
    cardHeading: {
        display: 'flex',
        marginBottom: '5px',
    },
    cardHeadingText: {
        width: '100%',
    },
};

const CohortListRow = ({ cohort, classes }) => (
    <Card className={classes.card} elevation={1}>
        <CardContent>
            <div className={classes.cardHeading}>
                <h5 className={classes.cardHeadingText}>Cohort</h5>
                {CohortTextHelper.shortStatusText(cohort)}
            </div>
            <Divider />
            <Grid className={classes.cardContent} container>
                <Grid item xs={6} sm={4}>
                    <div>
                        <strong>Status</strong>
                    </div>
                    <div>{CohortTextHelper.longStatusText(cohort)}</div>
                    <div>
                        <strong>Campus</strong>
                    </div>
                    {
                        cohort.campus
                        && <div>{`${cohort.campus.code} - ${cohort.campus.name}`}</div>
                    }
                    <div>
                        <strong>Program</strong>
                    </div>
                    {
                        cohort.campus
                        && <div>{`${cohort.campus.code} - ${cohort.campus.name}`}</div>
                    }
                    <div>
                        <strong>Instructors</strong>
                    </div>
                    {
                        cohort.employees
                        && cohort.employees.map(employee => (
                            <div key={employee.id}>{`${employee.first_name} ${employee.last_name}`}</div>
                        ))
                    }
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div>
                        <strong>Applications open</strong>
                    </div>
                    <div>
                        {cohort.applications_open}
                    </div>
                    <div>
                        <strong>Applications close</strong>
                    </div>
                    <div>
                        {cohort.applications_close}
                    </div>
                    <div>
                        <strong>Prework start</strong>
                    </div>
                    <div>
                        {cohort.prework_start}
                    </div>
                    <div>
                        <strong>Classroom start</strong>
                    </div>
                    <div>
                        {cohort.classroom_start}
                    </div>
                    <div>
                        <strong>Graduation</strong>
                    </div>
                    <div>
                        {cohort.graduation}
                    </div>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <div>
                        <strong>Target size</strong>
                    </div>
                    <div>{cohort.target_size}</div>
                    <div>
                        <strong>Confirmed</strong>
                    </div>
                    <div>
                        {cohort.students && cohort.students.length}
                    </div>
                    <div>
                        <strong>Placed</strong>
                    </div>
                    <div>{cohort.placement_count}</div>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

CohortListRow.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortListRow);

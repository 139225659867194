import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Typography,
} from '@material-ui/core';
import ExpandArrowIcon from '../../../General/ExpandArrowIcon';
import formatDollars from '../../../../helpers/CurrencyHelper';
// import formatDollars from '../../../../helpers/DateFormatHelper';


function StudentPlacementTableRow(props) {
    const { placement } = props;
    const [expanded, setExpanded] = useState(false);

    function handleExpansionClick() {
        setExpanded(!expanded);
    }

    return (
        <>
            <TableRow>
                <TableCell onClick={handleExpansionClick}>
                    <ExpandArrowIcon expanded={expanded} />
                </TableCell>
                <TableCell>{placement.company.name}</TableCell>
                <TableCell>{placement.cohort.name}</TableCell>
                <TableCell>{placement.placement_type}</TableCell>
                <TableCell>{placement.job_title}</TableCell>
                <TableCell>{`$${formatDollars(placement.post_income)}`}</TableCell>
                <TableCell>{placement.placed_at}</TableCell>
            </TableRow>
            {expanded && (
                <TableRow>
                    <TableCell colSpan={1} />

                    <TableCell>
                        <Typography variant="caption">Pre-Prime Salary:</Typography>
                        <Typography>{`$${formatDollars(placement.pre_income)}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Duration:</Typography>
                        <Typography>{placement.placement_duration}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Rate:</Typography>
                        <Typography>{placement.placement_rate}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">How:</Typography>
                        <Typography>{placement.how_found_notes}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Notes:</Typography>
                        <Typography>{placement.notes}</Typography>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}


StudentPlacementTableRow.propTypes = {
    placement: PropTypes.instanceOf(Object).isRequired,
};
export default StudentPlacementTableRow;

import axios from 'axios';
import {
    put, all, takeLatest, select,
} from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchStudentList(action) {
    try {
        const response = yield axios.get('api/students/list', { params: action.payload });
        yield put({ type: 'SET_STUDENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student list - get request failed', error);
    }
}

function* fetchStudentCohort(action) {
    try {
        const response = yield axios.get(`api/students/cohort/${action.payload.studentId}`);
        // Get cohort details for the selected student
        if (response.data.assigned_cohort_id) {
            yield put({ type: 'FETCH_COHORT_DETAILS', payload: { id: response.data.assigned_cohort_id } });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student cohort - get request failed', error);
    }
}

function* fetchStudentSummary(action) {
    try {
        const response = yield axios.get(`api/students/summary/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT', payload: response.data });
        yield put({ type: 'FETCH_STUDENT_NOTES', payload: action.payload });
        if (response.data.cohortId) {
            yield put({ type: 'FETCH_STUDENT_COHORT_INFO', payload: action.payload });
        }

        if (response.data.placement_count && response.data.placement_count !== '0') {
            yield put({ type: 'FETCH_STUDENT_PLACEMENTS', payload: action.payload });
        } else {
            yield put({ type: 'UNSET_STUDENT_PLACEMENTS' });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student summary - get request failed', error);
    }
}

function* fetchStudentPlacements(action) {
    try {
        const response = yield axios.get(`api/students/placements/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_PLACEMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Placements - get request failed', error);
    }
}

function* fetchStudentFeedback(action) {
    try {
        const response = yield axios.get(`api/students/feedback/student/${action.payload.studentId}`);
        if (Object.entries(response.data).length !== 0) {
            yield put({ type: 'SET_STUDENT_FEEDBACK', payload: response.data });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student detail - get request failed', error);
    }
}

function* fetchStudentCourseWork(action) {
    try {
        const response = yield axios.get(`api/students/work/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_COURSE_WORKS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student course work - get request failed', error);
    }
}

function* fetchStudentNotes(action) {
    try {
        const response = yield axios.get(`api/students/notes/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_NOTES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student notes - get request failed', error);
    }
}

// For non-prime admission process students
function* createStudent(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: newStudent } = yield axios.post('/api/students', action.payload);
        const { searchOffset: offset, searchText } = yield select(state => state.student);
        yield put({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/students/${newStudent.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// For non-prime admission process students
function* updateStudent(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: newStudent } = yield axios.put('/api/students/update', action.payload);
        const { searchOffset: offset, searchText } = yield select(state => state.student);
        yield put({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/students/${newStudent.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchStudentForEdit(action) {
    try {
        const response = yield axios.get(`api/students/summary/${action.payload.studentId}`);
        yield put({ type: 'SET_EDITING_STUDENT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student to edit - get request failed', error);
    }
}

function* fetchAllStudentInfo(action) {
    yield all([
        put({ type: 'FETCH_STUDENT_COHORT', payload: action.payload }),
        put({ type: 'FETCH_STUDENT_FEEDBACK', payload: action.payload }),
        put({ type: 'FETCH_STUDENT_COURSE_WORK', payload: action.payload }),
    ]);
}

function* userSaga() {
    yield takeLatest('FETCH_ALL_STUDENT_INFO', fetchStudentSummary);
    yield takeLatest('CREATE_STUDENT', createStudent);
    yield takeLatest('PUT_STUDENT', updateStudent);
    yield takeLatest('FETCH_STUDENT_LIST', fetchStudentList);
    yield takeLatest('FETCH_STUDENT_COHORT_INFO', fetchAllStudentInfo);
    yield takeLatest('FETCH_STUDENT_COHORT', fetchStudentCohort);
    yield takeLatest('FETCH_STUDENT_FEEDBACK', fetchStudentFeedback);
    yield takeLatest('FETCH_STUDENT_COURSE_WORK', fetchStudentCourseWork);
    yield takeLatest('FETCH_STUDENT_NOTES', fetchStudentNotes);
    yield takeLatest('FETCH_STUDENT_PLACEMENTS', fetchStudentPlacements);
    yield takeLatest('FETCH_STUDENT_FOR_EDIT', fetchStudentForEdit);
}

export default userSaga;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    List,
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import PageTitle from '../../PageTitle/PageTitle';
import CohortListRow from './CohortListRow';

const styles = theme => ({
    cohortList: {
        width: '100%',
    },
    searchInput: {
        width: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

class CohortList extends Component {
    state = {
        search: '',
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    viewCohortDetails = cohort => () => {
        const { dispatch } = this.props;
        const action = { type: 'SET_COHORT', payload: cohort };
        dispatch(action);
        const { history } = this.props;
        history.push(`/cohorts/${cohort.id}`);
    }

    handleSearchChange = (event) => {
        this.setState({
            search: event.target.value,
        });
    }

    render() {
        const { cohorts, history, classes } = this.props;
        const { search } = this.state;
        const filtered = cohorts.filter((cohort) => {
            let match = false;
            if (cohort.cohort_name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                match = true;
            } else if (search.toLowerCase() === 'ux' && cohort.course === 'User Experience Design') {
                match = true;
            } else if ((search.toLowerCase() === 'fs' || search.toLowerCase() === 'dev') && cohort.course === 'Full Stack Engineering') {
                match = true;
            } else if (search.toLowerCase() === cohort.campus_code.toLowerCase()) {
                match = true;
            }
            return match;
        });
        const now = moment();
        filtered.sort((a, b) => {
            const graduationDateA = moment(a.graduation);
            const graduationDateB = moment(b.graduation);
            let compare = 0;
            if (graduationDateA < now && graduationDateB > now) {
                compare = 1;
            } else if (graduationDateA > now && graduationDateB < now) {
                compare = -1;
            } else if (graduationDateA < graduationDateB) {
                compare = -1;
            } else if (graduationDateA > graduationDateB) {
                compare = 1;
            }
            return compare;
        });
        return (
            <div className="container">
                <PageTitle
                    title="Cohorts Home"
                    // Action buttons are created by the PageTitle component
                    actions={[
                        {
                            label: 'Add New Cohort',
                            // Dispatch an action...
                            type: 'UNSET_EDITING_COHORT',
                            // ...and navigate to this page.
                            path: '/cohorts/new',
                        },
                    ]}
                />
                <Paper>
                    <div className="search-bar">
                        <TextField helperText="Supported keywords: ux, dev, mn, kc and cohort name." className={classes.searchInput} label="Search" margin="dense" variant="outlined" onChange={this.handleSearchChange} />
                    </div>
                    <List className={classes.cohortList}>
                        {
                            filtered.map(cohort => (
                                <CohortListRow
                                    key={cohort.id}
                                    cohort={cohort}
                                    history={history}
                                    viewCohortDetails={this.viewCohortDetails}
                                />
                            ))
                        }
                    </List>
                </Paper>
            </div>
        );
    }
}

CohortList.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohorts: state.cohort.cohortList,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortList));

import React from 'react';
import { connect } from 'react-redux';
import {
    ListItem,
    Button,
    Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import CohortTextHelper from '../../../helpers/CohortTextHelper';
import ProgressBar from '../../General/ProgressBar';

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const styles = theme => ({
    cohortStatusText: {
        minWidth: '120px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingBottom: '5px',
        },
    },
    listRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
    },
    cohortTitleText: {
        maxWidth: '240px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
        },
    },
    placementText: {
        maxWidth: '80px',
    },
});

const CohortListRow = ({ cohort, classes, viewCohortDetails }) => (
    <ListItem
        divider
        button
        onClick={viewCohortDetails(cohort)}
        className={classes.listRow}
    >
        <Hidden smDown>
            <div className={classes.cohortStatusText}>
                {CohortTextHelper.shortStatusText(cohort)}
            </div>
        </Hidden>
        <div className={classes.cohortTitleText}>
            <h5>{cohort.cohort_name}</h5>
            <Hidden mdUp>
                <br />
                <div className={classes.cohortStatusText}>
                    {CohortTextHelper.shortStatusText(cohort)}
                </div>
                <br />
            </Hidden>
            <p>
                {cohort.campus}
                &nbsp;-&nbsp;
                {cohort.course}
            </p>
            <p>
                {moment(cohort.prework_start).format('MMMM Do YYYY')}
                &nbsp;-&nbsp;
                {moment(cohort.graduation).format('MMMM Do YYYY')}
                &nbsp;(
                {CohortTextHelper.longStatusText(cohort)}
                )
            </p>
        </div>
        <Hidden smDown>
            <div>
                <p>
                    Confirmed:&nbsp;
                    {cohort.confirmed_students}
                </p>
                <ProgressBar
                    fillPercent={(cohort.confirmed_students / cohort.target_size) * 100}
                />
            </div>
            <div>
                <p>
                    Placed:&nbsp;
                    {cohort.placed_students}
                    /
                    {cohort.total_students}
                </p>
                <ProgressBar
                    fillPercent={(cohort.placed_students / cohort.total_students) * 100}
                />
            </div>
            <div className={classes.placementText}>
                <p>
                    Avg. Days to Place:&nbsp;
                    {cohort.days_to_place}
                </p>
            </div>
            <div className={classes.placementText}>
                <p>
                    Avg. Salary:&nbsp;
                    {formatter.format(cohort.average_salary)}
                </p>
            </div>
            <div>
                <Button variant="outlined" onClick={viewCohortDetails(cohort)} type="button">View</Button>
            </div>
        </Hidden>
    </ListItem>
);


CohortListRow.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    viewCohortDetails: PropTypes.func.isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default connect()(withStyles(styles)(CohortListRow));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Tooltip,
    Icon,
} from '@material-ui/core';

const styles = {
    smallIcon: {
        margin: 0,
        padding: '0 4px',
        fontSize: '14px',
        height: 'auto',
        width: 'auto',
    },
};

const ProgressBar = ({ tooltip, title, classes }) => (
    <>
        {
            tooltip
            && tooltip.length > 0
            && (
                <Tooltip
                    title={(
                        <React.Fragment>
                            <Typography color="inherit">{title}</Typography>
                            {tooltip}
                        </React.Fragment>
                    )}
                >
                    <Icon className={classNames(classes.smallIcon, 'fa fa-comment')} />
                </Tooltip>
            )
        }
    </>
);

ProgressBar.propTypes = {
    tooltip: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ProgressBar);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Prompt } from 'react-router-dom';
import {
    Paper,
    DialogActions,
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
    Grid,
    Typography,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../../PageTitle/PageTitle';
import EmployeeSelect from './EmployeeSelect';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
};

class CreateCohortPage extends Component {
    constructor(props) {
        super(props);
        const { match, dispatch } = this.props;
        let existing = false;
        if (match.params.id) {
            existing = true;
        }
        this.state = {
            changes: false,
            // editing is set to true if editing an existing student
            existing,
            errorText: '',
        };

        if (existing) {
            dispatch({ type: 'FETCH_COHORT_FOR_EDIT', payload: { cohortId: match.params.id } });
        }
    }

    componentDidMount() {
        const { dispatch, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'FETCH_CAMPUS_LIST' });
        dispatch({ type: 'FETCH_EMPLOYEE_LIST' });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_EDITING_COHORT' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        if (!this.isNewCohort()) {
            dispatch({ type: 'FETCH_COHORT_FOR_EDIT', payload: { studentId: match.params.id } });
        }
    }

    getEmployeeSelectFields() {
        const {
            cohort,
            employeeList,
        } = this.props;
        // Add an empty selection for the select field.
        const emptyEmployee = { id: '', first_name: '' };
        const employeeListWithDefault = [emptyEmployee, ...employeeList];
        return (
            <>
                <Grid item xs={12} sm={6}>
                    <EmployeeSelect
                        index="1"
                        employeeList={employeeListWithDefault}
                        employee={cohort.employees[0]}
                        employeeChangeFor={this.employeeChangeFor}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <br />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <EmployeeSelect
                        index="2"
                        employeeList={employeeListWithDefault}
                        employee={cohort.employees[1]}
                        employeeChangeFor={this.employeeChangeFor}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <EmployeeSelect
                        index="3"
                        employeeList={employeeListWithDefault}
                        employee={cohort.employees[2]}
                        employeeChangeFor={this.employeeChangeFor}
                    />
                </Grid>
            </>
        );
    }

    handleSubmit = () => {
        const { dispatch, cohort, history } = this.props;
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        // TODO: Update changes after a successful save. Right now we're assuming
        // success.
        this.setState({
            changes: false,
        });

        const action = {
            type: this.isNewCohort() ? 'CREATE_COHORT' : 'PUT_COHORT',
            payload: cohort,
            history,
        };
        dispatch(action);
    }

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    }

    handleChangeFor = propertyName => (event) => {
        const { dispatch, cohort } = this.props;
        const updatedCohort = {
            ...cohort,
            [propertyName]: event.target.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_COHORT', payload: updatedCohort });
    }

    /**
     * Handle change for one of three select fields. These fields assign an
     * employee (instructor) to a cohort. A cohort can have 0 - 3 instructors
     * assigned. We may eventually want to make this dynamic. Right now it's
     * hard coded to three fields.
     */
    employeeChangeFor = index => (event) => {
        const {
            dispatch,
            cohort,
            employeeList,
        } = this.props;
        const emptyEmployee = { id: '', first_name: '' };
        const employeeListWithDefault = [emptyEmployee, ...employeeList];
        // MenuItem only retains the value (string or number), look up the
        // employee in the list based on value (employee id).
        const selectedEmployeeId = event.target.value;
        const selectedEmployee = employeeListWithDefault.find(employee => (
            employee.id === selectedEmployeeId
        ));
        // Set the employee at the index to the selected employee
        const updatedEmployeeList = cohort.employees.map((employee, i) => {
            if (i === Number(index) - 1) {
                return selectedEmployee;
            }
            return employee;
        });
        const updatedCohort = {
            ...cohort,
            employees: updatedEmployeeList,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_COHORT', payload: updatedCohort });
    }

    validateForm = () => {
        const { cohort } = this.props;
        let valid = true;
        // Probably check others?
        if (cohort.name === '') {
            valid = false;
            this.setState({
                errorText: 'Cohort name is required.',
            });
        } else if (!cohort.course_id || cohort.course_id === '') {
            valid = false;
            this.setState({
                errorText: 'Program is required.',
            });
        } else {
            this.setState({
                errorText: '',
            });
        }
        return valid;
    }

    isNewCohort() {
        const { match } = this.props;
        if (match.params.id) {
            return false;
        }
        return true;
    }

    render() {
        const {
            classes,
            cohort,
            campuses,
        } = this.props;
        const {
            errorText,
            changes,
            existing,
        } = this.state;
        let pageTitle = 'Create New Cohort';
        if (existing) {
            pageTitle = `Edit Cohort: ${cohort.name}`;
        }
        return (
            <>
                {/*
                    For a custom rendered modal, we may want to switch to this:
                    https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
                */}
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                    />
                    <Paper>
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <div align="left" className={classes.feedbackContainer}>
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>GENERAL</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="cohort-name"
                                            label="Cohort Name"
                                            className={classes.textField}
                                            value={cohort.name}
                                            onChange={this.handleChangeFor('name')}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="target-size"
                                            label="Target size"
                                            className={classes.textField}
                                            value={cohort.target_size}
                                            onChange={this.handleChangeFor('target_size')}
                                            margin="normal"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl required className={classes.formControl}>
                                            <InputLabel id="campus_id_label">Campus</InputLabel>
                                            <Select
                                                labelid="campus_id_label"
                                                id="campus_id"
                                                value={cohort.campus_id}
                                                onChange={this.handleChangeFor('campus_id')}
                                            >
                                                {
                                                    campuses
                                                    && campuses.map(campus => (
                                                        <MenuItem value={campus.id}>
                                                            {campus.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        cohort.employees
                                        && cohort.employees.length === 3
                                            ? (
                                                this.getEmployeeSelectFields()
                                            ) : (
                                                <Grid item xs={12} sm={6}>
                                                    <p>Warning. Intructor list missing.</p>
                                                </Grid>
                                            )
                                    }
                                    <Grid item xs={12} sm={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormLabel component="legend">Program</FormLabel>
                                        <RadioGroup
                                            required
                                            aria-label="course_id"
                                            name="course_id"
                                            value={String(cohort.course_id)}
                                            onChange={this.handleChangeFor('course_id')}
                                            row
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio color="primary" />}
                                                label="Full-Stack"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="2"
                                                control={<Radio color="primary" />}
                                                label="User Experience"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormLabel component="legend">Feedback email nagging</FormLabel>
                                        <RadioGroup
                                            required
                                            aria-label="feedback_nag"
                                            name="feedback_nag"
                                            value={String(cohort.feedback_nag)}
                                            onChange={this.handleChangeFor('feedback_nag')}
                                            row
                                        >
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio color="primary" />}
                                                label="Inactive"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio color="primary" />}
                                                label="Active"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="applications_open"
                                            label="Applications open"
                                            type="date"
                                            className={classes.textField}
                                            value={cohort.applications_open}
                                            onChange={this.handleChangeFor('applications_open')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="applications_close"
                                            label="Applications close"
                                            type="date"
                                            className={classes.textField}
                                            value={cohort.applications_close}
                                            onChange={this.handleChangeFor('applications_close')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="date"
                                            label="Prework start"
                                            type="date"
                                            className={classes.textField}
                                            value={cohort.prework_start}
                                            onChange={this.handleChangeFor('prework_start')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="classroom_start"
                                            label="Classroom start"
                                            type="date"
                                            className={classes.textField}
                                            value={cohort.classroom_start}
                                            onChange={this.handleChangeFor('classroom_start')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="graduation"
                                            label="Graduation"
                                            type="date"
                                            className={classes.textField}
                                            value={cohort.graduation}
                                            onChange={this.handleChangeFor('graduation')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <br />
                        <br />
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <DialogActions>
                            <Button
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                {existing ? <span>Save</span> : <span>Create</span>}
                            </Button>
                        </DialogActions>
                    </Paper>
                </div>
            </>
        );
    }
}

CreateCohortPage.defaultProps = {
};

CreateCohortPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    campuses: PropTypes.instanceOf(Array).isRequired,
    employeeList: PropTypes.instanceOf(Array).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = state => ({
    cohort: state.cohort.editingCohort,
    employeeList: state.employee.employeeList,
    campuses: state.campus,
    tags: state.assignments.tags,
});
const createCohortPage = withMobileDialog()(withStyles(styles)(CreateCohortPage));
export default connect(mapStateToProps)(createCohortPage);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Grid,
    Select,
    MenuItem,
    OutlinedInput,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { extendedDateTime } from '../../helpers/DateFormatHelper';
import CodeBlock from '../General/CodeBlock';

const styles = theme => ({
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
        minWidth: '768px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100px',
        },
        padding: '0px',
    },
    modalContainer: {
        // overflow: 'scroll',
        minWidth: '1000px',
    },
    feedbackContainer: {
        backgroundColor: '#f8fafb',
        margin: '0px',
        padding: '20px',
        borderTop: '1px solid #bbb',
        borderBottom: '1px solid #bbb',
    },
    feedback: {
        backgroundColor: '#fff',
    },
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

class CourseWorkModal extends Component {
    constructor(props) {
        super(props);
        const { courseWork } = props;
        let selectedTab = 'write';
        if (courseWork && courseWork.instructor_comment) {
            selectedTab = 'preview';
        }
        this.state = {
            changes: false,
            selectedTab,
            exitPrompt: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const {
            dispatch,
            open,
            courseWork,
            handleRefresh,
        } = this.props;
        const { changes } = this.state;

        if (changes && open && !nextProps.open) {
            dispatch({ type: 'UNSET_ASSIGNMENT_COURSE_WORK' });
            this.setState({
                changes: false,
            });
            // Modal is closing, notify refresh handler
            handleRefresh();
        }
        // Current courseWork is empty {} which means we're recieving
        // this prop for the first time. If the incomming props has course
        // work that includes feedback, then we should default to 'preview'.
        console.log(courseWork, nextProps.courseWork);
        if (courseWork
            && nextProps.courseWork
            && Object.keys(nextProps.courseWork).length > 0) {
            if (courseWork.id !== nextProps.courseWork.id
                && nextProps.courseWork.instructor_comment
                && nextProps.courseWork.instructor_comment.length > 0) {
                this.setState({
                    selectedTab: 'preview',
                });
            }
        }
    }

    handleGradeChange = (event) => {
        const { dispatch, courseWork } = this.props;
        const updatedCoursework = {
            ...courseWork,
            grade: event.target.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: updatedCoursework });
    }

    handleMdeChange = (value) => {
        const { dispatch, courseWork } = this.props;
        const updatedCoursework = {
            ...courseWork,
            instructor_comment: value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: updatedCoursework });
    }

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value,
        });
    }

    handleSave = () => {
        const { dispatch, closeActionType, courseWork } = this.props;
        dispatch({ type: 'GRADE_COURSE_WORK', payload: courseWork });
        dispatch({
            type: closeActionType,
            payload: {
                modalOpen: false,
            },
        });
        this.setState({
            exitPrompt: false,
        });
    }

    handleClose = () => {
        const { changes } = this.state;
        const { dispatch, closeActionType } = this.props;

        if (changes) {
            this.setState({
                exitPrompt: true,
            });
        } else {
            dispatch({
                type: closeActionType,
                payload: {
                    modalOpen: false,
                },
            });
        }
    }

    clearFieldsAndExit = () => {
        const { dispatch, closeActionType } = this.props;
        dispatch({ type: 'UNSET_ASSIGNMENT_COURSE_WORK' });
        this.setState({
            exitPrompt: false,
            changes: false,
        });
        dispatch({
            type: closeActionType,
            payload: {
                modalOpen: false,
            },
        });
    }

    render() {
        const {
            student,
            classes,
            open,
            fullScreen,
            assignment,
            courseWork,
        } = this.props;
        const {
            changes,
            selectedTab,
            exitPrompt,
        } = this.state;
        let studentComment = 'No student comment.';
        if (courseWork && courseWork.student_comment) {
            studentComment = courseWork.student_comment;
        }
        let feedback = '';
        if (courseWork && courseWork.instructor_comment) {
            feedback = courseWork.instructor_comment;
        }
        let existingGrade = '';
        if (courseWork.grade) {
            existingGrade = courseWork.grade;
        }
        const completedAt = extendedDateTime(courseWork.completed_at);
        const dueAt = extendedDateTime(assignment.due_at);
        return (
            <>
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />
                <Dialog open={exitPrompt}>
                    <Helmet>
                        <title>Warning! Unsaved Changes</title>
                    </Helmet>
                    <DialogTitle id="responsive-dialog-title">
                        You have unsaved changes.
                    </DialogTitle>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.clearFieldsAndExit}
                    >
                        Discard Changes
                    </Button>
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSave}
                    >
                        Save Changes
                    </Button>
                    <br />
                    <Button
                        onClick={
                            () => this.setState({
                                exitPrompt: false,
                            })
                        }
                    >
                        Cancel
                    </Button>
                </Dialog>
                <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={fullScreen} open={open} className="modalContainer">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Helmet>
                            <title>Grading Assignment</title>
                        </Helmet>
                        <DialogTitle id="responsive-dialog-title" style={{ flex: 1 }}>
                            {assignment.name}
                        </DialogTitle>
                        <Button
                            style={{ height: '44px', margin: '20px' }}
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                    </div>

                    <div className={classes.feedbackContainer}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} className={classes.gridItem}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item xs={12}>
                                        <strong>Student:</strong>
                                        <br />
                                        <Link to={`/students/${student.id}`}>
                                            {`${student.first_name} ${student.last_name}`}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridItem}>
                                        <strong>Repository:</strong>
                                        <br />
                                        <a
                                            href={courseWork.repository}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {courseWork.repository}
                                        </a>
                                    </Grid>
                                    {
                                        courseWork.site
                                        && (
                                            <>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <strong>Website:</strong>
                                                    <br />
                                                    <a
                                                        href={courseWork.site}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {courseWork.site}
                                                    </a>
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={1} direction="column">
                                    <Grid item xs={12}>
                                        <strong>Due at:</strong>
                                        <br />
                                        {dueAt}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong>Completed at:</strong>
                                        <br />
                                        {completedAt}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <h5>Student Comment:</h5>
                        <div>
                            {studentComment}
                        </div>
                        <br />
                        {
                            assignment.assignment_type === 'graded'
                            && (
                                <>
                                    <h5>Grade:</h5>
                                    <div>
                                        <Select
                                            value={existingGrade}
                                            onChange={this.handleGradeChange}
                                            input={<OutlinedInput labelWidth={300} name="grade" id="outlined-age-simple" />}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>1 - Reinforcement Needed</MenuItem>
                                            <MenuItem value={3}>3 - Meets Expectations</MenuItem>
                                            <MenuItem value={5}>5 - Exceeds Expectations</MenuItem>
                                        </Select>
                                    </div>
                                </>
                            )
                        }
                        <br />
                        <h5>Instructor Feedback</h5>
                        <hr />
                        {
                            feedback !== ''
                            && courseWork.graded_by_id
                            && courseWork.employee
                            && (
                                <p>
                                    Graded by:
                                    {`${courseWork.employee.first_name} ${courseWork.employee.last_name}`}
                                </p>
                            )
                        }
                        <ReactMde
                            className={classes.feedback}
                            value={feedback}
                            onChange={this.handleMdeChange}
                            selectedTab={selectedTab}
                            onTabChange={this.setSelectedTab}
                            generateMarkdownPreview={markdown => (
                                Promise.resolve(
                                    <ReactMarkdown
                                        source={markdown}
                                        renderers={{ code: CodeBlock }}
                                    />,
                                )
                            )}
                        />
                    </div>
                    <br />
                    <DialogActions>
                        <Button
                            onClick={this.handleSave}
                            disabled={!changes}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

CourseWorkModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    closeActionType: PropTypes.string.isRequired,
};

export default connect()(withMobileDialog()(withStyles(styles)(CourseWorkModal)));

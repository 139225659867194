import React from 'react';
import {
    HashRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import TopBar from '../TopBar/TopBar';
import LoginPage from '../LoginPage/LoginPage';
import CohortListPage from '../Cohort/CohortListPage/CohortListPage';
import CohortStudentsPage from '../Cohort/CohortStudentsPage/CohortStudentsPage';
import CohortAssignmentsPage from '../Cohort/CohortAssignmentsPage/CohortAssignmentsPage';
import CohortFeedbackPage from '../Cohort/CohortFeedbackPage/CohortFeedbackPage';
import CreateCohortPage from '../Cohort/CreateCohortPage/CreateCohortPage';
import EmployeeListPage from '../Settings/EmployeeListPage/EmployeeListPage';
import CreateEmployeePage from '../Settings/CreateEmployeePage/CreateEmployeePage';
import StudentListPage from '../Student/StudentListPage/StudentListPage';
import StudentDetailPage from '../Student/StudentDetailPage/StudentDetailPage';
import CreateStudentPage from '../Student/CreateStudentPage/CreateStudentPage';
import AssignmentDetailPage from '../Assignment/AssignmentDetailPage/AssignmentDetailPage';
import AssignmentListPage from '../Assignment/AssignmentListPage/AssignmentListPage';
import CreateAssignmentPage from '../Assignment/CreateAssignmentPage/CreateAssignmentPage';
import StudentAssignmentsPage from '../StudentPortal/StudentAssignments/StudentAssignments';
import StudentFeedbackPage from '../StudentPortal/StudentFeedback/StudentFeedback';
import StudentAssignmentDetail from '../StudentPortal/StudentAssignments/StudentAssignmentDetail';
import SideNav from '../SideNav/SideNav';
import Theme from './Theme';
import ErrorMessageModal from '../Modal/ErrorMessageModal';
import SavingModal from '../Modal/SavingModal';
import AuthRoute from '../General/AuthRoute';
import './App.css';
import ResetPasswordPage from '../LoginPage/ResetPasswordPage';
import ProfilePage from '../Profile/ProfilePage';
import GlobalAlertModal from '../Modal/GlobalAlertModal';

const { lightTheme, darkTheme } = Theme;
const { STUDENT, EMPLOYEE } = require('../General/UserTypes');

const styles = {
    root: {
        display: 'flex',
    },
    appBar: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
    },
};

const App = ({ classes }) => (
    <MuiThemeProvider theme={lightTheme}>
        <ErrorMessageModal
            closeActionType="SET_ERROR_MODAL"
        />
        <GlobalAlertModal
            closeActionType="SET_GLOBAL_MODAL"
        />
        <SavingModal />
        <Router>
            <div className={classes.root}>
                <ScrollToTop />
                <MuiThemeProvider theme={darkTheme}>
                    <SideNav />
                </MuiThemeProvider>
                <div className={classes.content}>
                    <TopBar className={classes.appBar} />
                    <Switch>
                        <AuthRoute
                            exact
                            path="/"
                            Component={LoginPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <Route
                            exact
                            path="/login"
                            component={LoginPage}
                        />
                        <Route
                            exact
                            path="/password/reset/:token"
                            component={ResetPasswordPage}
                        />
                        <AuthRoute
                            exact
                            path="/profile"
                            Component={ProfilePage}
                            requiredRoles={[EMPLOYEE, STUDENT]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts"
                            Component={CohortListPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts/new"
                            Component={CreateCohortPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts/:id"
                            Component={CohortStudentsPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts/:id/edit"
                            Component={CreateCohortPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts/:id/assignments"
                            Component={CohortAssignmentsPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/cohorts/:id/feedback"
                            Component={CohortFeedbackPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/employees"
                            Component={EmployeeListPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/employees/new"
                            Component={CreateEmployeePage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/employees/:id/edit"
                            Component={CreateEmployeePage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/students"
                            Component={StudentListPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/students/new"
                            Component={CreateStudentPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/students/:id/edit"
                            Component={CreateStudentPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/students/:id"
                            Component={StudentDetailPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/assignments"
                            Component={AssignmentListPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/assignments/new"
                            Component={CreateAssignmentPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/assignments/:id"
                            Component={AssignmentDetailPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/assignments/:id/edit"
                            Component={CreateAssignmentPage}
                            requiredRoles={[EMPLOYEE]}
                        />
                        <AuthRoute
                            exact
                            path="/student/assignments"
                            Component={StudentAssignmentsPage}
                            requiredRoles={[STUDENT]}
                        />
                        <AuthRoute
                            exact
                            path="/student/assignments/:id"
                            Component={StudentAssignmentDetail}
                            requiredRoles={[STUDENT]}
                        />
                        <AuthRoute
                            exact
                            path="/student/reflections"
                            Component={StudentFeedbackPage}
                            requiredRoles={[STUDENT]}
                        />
                        {/* If none of the other routes matched, we will show a 404. */}
                        <Route render={() => <h1>404</h1>} />
                    </Switch>
                </div>
            </div>
        </Router>
    </MuiThemeProvider>
);

App.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(App);

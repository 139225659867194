import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Hidden, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    topBar: {
        backgroundColor: '#F3F3F4',
        minHeight: '22px',
        width: '100%',
    },
    content: {
        padding: '12px 0px 12px 0px',
        // margin: '0px 15px',
        display: 'flex',
    },
    highlight: {
        backgroundColor: theme.palette.primary.main,
        height: '5px',
    },
    loggedInUser: {
        textAlign: 'right',
        margin: '0px 10px',
        minWidth: '100px',
    },
    leftButtons: {
        padding: '0px 10px',
        minWidth: '180px',
    },
    titleText: {
        width: '100%',
        textAlign: 'center',
    },
    rightButtons: {
        textAlign: 'right',
        minWidth: '180px',
        padding: '2px 0px 10px 0px',
    },
    icon: {
        margin: 0,
        padding: '1px', // theme.spacing(),
        fontSize: '20px',
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

class TopBar extends Component {
    componentDidMount() {
        const { dispatch, location, history } = this.props;
        if (location.pathname !== '/' && location.pathname !== '/login') {
            dispatch({ type: 'FETCH_USER' });
        }
        history.listen(this.onRouteChange.bind(this));
    }

    // This function is here to pick up the browser back button. It avoids
    // accidentally going back to far.
    onRouteChange(route) {
        const { dispatch, history } = this.props;
        // Prompt before going to the login page.
        if (route.pathname === '/') {
            if (!window.confirm('Are you sure you want to leave?')) {
                history.push('/cohorts');
            } else {
                dispatch({ type: 'LOGOUT' });
                history.push('/login');
            }
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    // This function is run when the user hits the back button on the page.
    handleLogout = () => {
        const { history, dispatch } = this.props;
        if (window.confirm('Are you sure you want to leave?')) {
            dispatch({ type: 'LOGOUT' });
            history.push('/login');
        }
    }

    handleDrawerToggle = () => {
        const { dispatch, drawerOpen } = this.props;
        dispatch({ type: 'SET_DRAWER_STATE', payload: { drawerOpen: !drawerOpen } });
    };

    render() {
        const {
            user,
            classes,
            displayBack,
            backText,
        } = this.props;
        return (
            <div className={classes.topBar}>
                <div className={classes.highlight} />
                <div className={classes.content}>
                    {
                        user
                        && user.first_name
                        && (
                            <div className={classes.leftButtons}>
                                {displayBack ? (
                                    <Button onClick={this.handleBack} variant="outlined" type="button">{backText}</Button>
                                ) : (
                                    <Button className={classes.menuButton} onClick={this.handleDrawerToggle} type="button">
                                        <Icon
                                            className={classNames(classes.icon, 'fa fa-bars')}
                                        />
                                    </Button>
                                )}
                            </div>
                        )
                    }
                    <div className={classes.titleText}>
                        <Hidden xsDown>
                            <h4>{user.role === 1 ? 'Student Portal' : 'Admin Portal' }</h4>
                        </Hidden>
                    </div>
                    {
                        user
                        && user.first_name
                        && (
                            <div className={classes.rightButtons}>
                                <p className={classes.loggedInUser}>
                                    {`${user.first_name} ${user.last_name}`}
                                </p>
                                <span className={classes.loggedInUser}>
                                    <Button onClick={this.handleLogout}>Logout</Button>
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

TopBar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    displayBack: PropTypes.bool.isRequired,
    backText: PropTypes.string.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.number,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    drawerOpen: PropTypes.bool.isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    displayBack: state.navigation.displayBack,
    backText: state.navigation.backText,
    drawerOpen: state.drawer.drawerOpen,
    error: state.error.currentError,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(withRouter(TopBar)));

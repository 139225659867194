
const React = require('react');
const PropTypes = require('prop-types');

const Prism = require('prismjs');

class CodeBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    componentDidMount() {
        this.highlightCode();
    }

    componentDidUpdate() {
        this.highlightCode();
    }

    setRef(el) {
        this.codeEl = el;
    }

    highlightCode() {
        Prism.highlightElement(this.codeEl);
        // Pri.highlightBlock();
    }

    render() {
        const { value, language } = this.props;
        let codeLanguage = language;
        if (codeLanguage === null) {
            // Default to JSX
            codeLanguage = 'javascript';
        }
        return (
            <pre>
                <code ref={this.setRef} className={`language-${codeLanguage}`}>
                    {value}
                </code>
            </pre>
        );
    }
}

CodeBlock.defaultProps = {
    language: '',
};

CodeBlock.propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string,
};

export default CodeBlock;

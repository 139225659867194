import { combineReducers } from 'redux';

// List of assignments assigned to the logged in student
const assignments = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_PORTAL_ASSIGNMENTS':
        return action.payload;
    default:
        return state;
    }
};

// List of feedback (reflections) completed by the logged in student
const feedbackHistory = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_PORTAL_FEEDBACK_HISTORY':
        return action.payload;
    default:
        return state;
    }
};

const feedbackDateCompare = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_PORTAL_FEEDBACK_LATEST':
        return action.payload;
    default:
        return state;
    }
};

// Course work to display on the student assignment detail page
const selectedCourseWork = (state = { repository: '', site: '', student_comment: '' }, action) => {
    let copy;
    switch (action.type) {
    case 'SET_STUDENT_PORTAL_COURSE_WORK':
        copy = Object.assign({}, action.payload);
        // Add default values if undefined. TextInput requires these to be defined.
        if (!copy.repository) {
            copy.repository = '';
        }
        if (!copy.site) {
            copy.site = '';
        }
        if (!copy.student_comment) {
            copy.student_comment = '';
        }
        return copy;
    case 'UNSET_STUDENT_PORTAL_COURSE_WORK':
        return { repository: '', site: '', student_comment: '' };
    default:
        return state;
    }
};

// Cohort that the currently logged in student is enrolled in
const cohort = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_PORTAL_COHORT':
        return action.payload;
    case 'UNSET_STUDENT_PORTAL_COHORT':
        return {};
    default:
        return state;
    }
};

// Boolean value that hides / shows the submit course work modal
const assignmentModalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_STUDENT_COURSE_WORK_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

const defaultFeedback = {
    thanks: '',
    recommended_experiences: '',
    understanding_explanation: '',
    support_explanation: '',
    feeling_explanation: '',
    accomplishment: '',
    additional_conversation_wanted: false,
};

const studentFeedback = (state = defaultFeedback, action) => {
    switch (action.type) {
    case 'SET_STUDENT_DAILY_FEEDBACK_ERROR':
        return {
            ...state,
            formError: true,
        };
    case 'SET_STUDENT_DAILY_FEEDBACK':
        return {
            ...state,
            [action.payload.key]: action.payload.value,
            formError: false,
        };
    case 'UNSET_STUDENT_DAILY_FEEDBACK':
        return defaultFeedback;
    default:
        return state;
    }
};

const selectedFeedback = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_SELECTED_FEEDBACK':
        return action.payload;
    case 'UNSET_STUDENT_SELECTED_FEEDBACK':
        return {};
    default:
        return state;
    }
};

const additionalConversationModalOpen = (state = false, action) => {
    switch (action.type) {
    case 'STUDENT_ADDITIONAL_CONVERSATION_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

export default combineReducers({
    assignments,
    selectedCourseWork,
    cohort,
    assignmentModalOpen,
    studentFeedback,
    feedbackHistory,
    feedbackDateCompare,
    additionalConversationModalOpen,
    selectedFeedback,
});
